import React, { useCallback, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Routes } from "../../../app/route/RoutesConfig";
import TeamUsersState from "../../../slices/customers/users/TeamUsersState";
import {
  deleteUser,
  impersonateAgent,
  impersonateUser,
  resetErrors,
  updateUser,
  updateUserInviteData,
  updateUserProfile,
  usersSelector
} from "../../../slices/customers/users/teamUsersSlice";
import InviteManagementState from "../../../slices/invite-management/InviteManagementState";
import {
  cancelInvite,
  inviteManagementSelector,
  resendInvite
} from "../../../slices/invite-management/inviteManagementSlice";
import CompanySettingsAccountsState from "../../../slices/company-settings/CompanySettingsAccountsState";
import { companySettingsAccountsSelector } from "../../../slices/company-settings/companySettingsAccountsSlice";
import UserProfile, { UserPasswordChangeForm, UserPasswordChangeFormSchema } from "../../../app/data/user/UserProfile";
import { customerRoles } from "../../../app/data/common/customerRoles";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import XGSInlineSwitch from "../../../ui-components/xgs-inline-switch/xgsInlineSwitch";
import XGSInput from "../../../ui-components/xgs-input/xgsInput";
import XGSPhoneInput from "../../../ui-components/xgs-phone-input/xgsPhoneInput";
import Button, { ButtonSizes, ButtonThemes } from "../../../ui-components/button/button";
import Loading from "../../../ui-components/loading/loading";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";
import { ReactComponent as ViewIcon } from "../../../images/view.svg";
import {
  ACCESS_LEVELS,
  userStatuses
} from "../constants";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import { UserUtils } from "../../../app/data/user/userUtils";
import { copyTextToClipboard } from "../../../hooks/utils";
import "./userDetails.scss";

import UserDetailsAccountsPermissions from "./userDetailsAccountsPermissions";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import ChangeUserPasswordState from "../../../slices/user/ChangeUserPasswordState";
import {
  changeUserPasswordSelector,
  changeUserPassword,
  resetChangeUserPassword
} from "../../../slices/user/changeUserPasswordSlice";
import { agentRoles } from "../../../app/data/common/agentRoles";
import { UserPasswordChangeRequestModel } from "../../../app/data/user/requestModels";
import { ERROR_MESSAGES } from "../../../app/data/common/errorMessages";
import { UserRoles } from "../../../app/data/user/enums";

export interface UserDetailsProps {
  id: string | undefined;
  show: boolean;
  onClose: () => void;
  onDelete: () => void;
  additionalFields?: boolean;
  customer?: boolean;
}

const UserDetails: React.FC<UserDetailsProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const teamUsersState: TeamUsersState = useSelector(usersSelector);
  const userState: UserState = useSelector(userSelector);
  const userPasswordState: ChangeUserPasswordState = useSelector(changeUserPasswordSelector);
  const inviteManagementState: InviteManagementState = useSelector(inviteManagementSelector);
  const companySettingsAccountsState: CompanySettingsAccountsState = useSelector(companySettingsAccountsSelector);
  const [userData, setUserData] = useState<UserProfile>();
  const [userRole, setUserRole] = useState<XGSSelectOption | null>();
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [showImpersonateConfirmation, setShowImpersonateConfirmation] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [returnsVisibility, setReturnsVisibility] = useState<XGSSelectOption | null>();
  const [pickupsVisibility, setPickupsVisibility] = useState<XGSSelectOption | null>();
  const [visibilitySpinner, setVisibilitySpinner] = useState<string>("");
  const passwordFormRef = useRef<any>(null);

  const passwordInitialValues: UserPasswordChangeForm = {
    newPassword: "",
    newPasswordRepeat: ""
  };

  const updateProfile = (newData: any) => {
    if (!userData) return;
    dispatch(updateUserProfile(newData, userData.id, () => {
      toast.info("The profile was updated!");
    }));
  };

  const saveRole = () => {
    if (!userData?.id || !userRole?.value) return;
    dispatch(updateUser(userData.id, "role", userRole.value, () => {
      toast.info("The user role has been changed!");
    }));
  };

  const onEnableUser = () => {
    if (!userData?.id) return;
    dispatch(updateUser(userData.id, "status", "APPROVED", () => {
      toast.info("The user was enabled!");
    }));
  };

  const onDisableUser = () => {
    if (!userData?.id) return;
    dispatch(updateUser(userData.id, "status", "DISABLED", () => {
      toast.info("The user was disabled!");
    }));
  };

  const onResendInvite = () => {
    if (!userData?.email) return;
    dispatch(resendInvite(userData.email, (newLink: string) => {
      dispatch(updateUserInviteData(userData.id, {
        date: new Date().toISOString(),
        link: newLink
      }, () => {
        toast.info("The invite was resent!");
      }));
    }));
  };

  const onCancelInvite = () => {
    if (!userData?.email) return;
    dispatch(cancelInvite(userData.email, () => {
      toast.info("The invite was canceled!");
      props.onDelete();
      props.onClose();
    }));
  };

  const onDeleteUser = () => {
    if (!userData?.id) return;
    dispatch(deleteUser(userData.id, () => {
      setShowRemoveConfirmation(false);
      toast.info("The user was deleted!");
      props.onDelete();
      props.onClose();
    }));
  };

  const onImpersonate = () => {
    if (!userData?.email) return;
    dispatch(impersonateUser(userData.email, (accessToken) => {
      localStorage.setItem("xgs-impersonate-token", accessToken);
      setShowImpersonateConfirmation(false);
      history.push(Routes.home);
      window.location.reload();
    }));
  };

  const onImpersonateAgent = () => {
    if (!userData?.email) return;
    dispatch(impersonateAgent(userData.email, (tokenId) => {
      let agentUrl = `${process.env.REACT_APP_AGENT_DOMAIN}/impersonate?tokenId=${tokenId}`;
      window.open(agentUrl, "_blank");
      setShowImpersonateConfirmation(false);
    }));
  }

  const onPasswordSubmit = (formData: UserPasswordChangeForm, { resetForm }: any) => {
    const request: UserPasswordChangeRequestModel = {
      userId: "",
      newPassword: formData.newPassword,
    }
    if (userData) request.userId = userData.id

    dispatch(changeUserPassword(request, () => {
      toast.info("Password changed!")
      resetForm()
    }))
  };

  const resetRole = () => {
    userData?.role && setUserRole(getUserRoleOptions().find(role => role.value === userData.role));
  };

  const visibilityLevel = (service: string) => {
    return userData?.serviceAccessLevels.find(obj => obj.service === service)?.level;
  };

  const saveAccessLevelSettings = (service: string, value?: string) => {
    if (!userData?.id || !service || !value) return;
    setVisibilitySpinner(service);
    dispatch(updateUser(userData.id, "serviceAccessLevels", [{
      service: service,
      level: value
    }], () => {
      toast.info("Visibility settings have been changed!");
      setVisibilitySpinner("");
    }));
  };

  const resetAccessLevelSettings = (service: string) => {
    const visibilityValue = userData?.serviceAccessLevels.find(obj => obj.service === service)?.level;
    const visibilityOption = ACCESS_LEVELS.find(obj => obj.value === visibilityValue);
    if (!visibilityOption) return;
    if (service === "RETURN") setReturnsVisibility(visibilityOption);
    if (service === "PICKUP") setPickupsVisibility(visibilityOption);
  };

  const getUserRoleOptions = useCallback(() => {
    return UserUtils.isAgent(userData) ? agentRoles : customerRoles;
  }, [userData]);

  useEffect(() => {
    if (!props.id) return;
    const user = teamUsersState.users?.find(user => user.id === props.id);
    dispatch(resetErrors());
    setUserData(user);
    // User role
    user?.role && setUserRole(getUserRoleOptions().find(role => role.value === user.role));
    // Returns visibility
    const returnsVisibilityValue = user?.serviceAccessLevels.find(obj => obj.service === "RETURN")?.level;
    const returnsVisibilityOption = ACCESS_LEVELS.find(obj => obj.value === returnsVisibilityValue);
    returnsVisibilityOption && setReturnsVisibility(returnsVisibilityOption);
    // Pickups visibility
    const pickupsVisibilityValue = user?.serviceAccessLevels.find(obj => obj.service === "PICKUP")?.level;
    const pickupsVisibilityOption = ACCESS_LEVELS.find(obj => obj.value === pickupsVisibilityValue);
    pickupsVisibilityOption && setPickupsVisibility(pickupsVisibilityOption);
  }, [dispatch, teamUsersState.users, props.id, getUserRoleOptions]);

  useEffect(() => {
    if (!teamUsersState.update_was_failed) return;
    toast.error(teamUsersState.update_fail_reason || ERROR_MESSAGES.COMMON);
    setVisibilitySpinner("");
    dispatch(resetErrors());
  }, [teamUsersState.update_was_failed, teamUsersState.update_fail_reason, dispatch]);

  useEffect(() => {
    setFirstName(userData?.firstName || "");
    setLastName(userData?.lastName || "");
    setTitle(userData?.title || "");
    setEmail(userData?.email || "");
    setPhoneNumber(userData?.phoneNumber || "");
    dispatch(resetChangeUserPassword())
  }, [userData, dispatch]);

  return (
    <SlideOutSidebar
      header="User information"
      onClose={props.onClose}
      show={props.show}
      spinner={(teamUsersState.update_was_started && teamUsersState.update_creator === "UPDATE_PROFILE") || companySettingsAccountsState.update_was_started || (teamUsersState.fetch_was_started && teamUsersState.requestCreator === "GET_PERMISSIONS")}
    >
      {userData && (
        <div className="xgs-company__users__details" key={props.id}>
          <div className="xgs-sidebar__details__name-block">
            <div className="xgs-sidebar__details__avatar">{ userData.firstName?.charAt(0) }</div>
            <div className="xgs-sidebar__details__content">
              <div className="xgs-sidebar__details__name">
                <XGSInlineSwitch
                  name="name"
                  staticContent={<>{ userData.firstName } { userData.lastName }</>}
                  formElement={
                    <>
                      <XGSInput
                        type="text"
                        name="firstName"
                        value={firstName}
                        placeholder="First Name"
                        onChange={(e) => setFirstName(e.currentTarget.value)}
                      />
                      &nbsp;
                      <XGSInput
                        type="text"
                        name="lastName"
                        value={lastName}
                        placeholder="Last Name"
                        onChange={(e) => setLastName(e.currentTarget.value)}
                      />
                    </>
                  }
                  onCancel={() => {
                    setFirstName(userData.firstName);
                    setLastName(userData.lastName);
                  }}
                  onSubmit={() => updateProfile({firstName, lastName})}
                  disabled={props.customer}
                />
              </div>
              <div className="xgs-sidebar__details__title">
                <XGSInlineSwitch
                  name="title"
                  staticContent={userData.title || <>(no title)</>}
                  formElement={
                    <XGSInput
                      type="text"
                      name="title"
                      value={title}
                      placeholder="e.g. Sales Manager"
                      onChange={(e) => setTitle(e.currentTarget.value)}
                    />
                  }
                  onCancel={() => setTitle(userData.title)}
                  onSubmit={() => updateProfile({title})}
                  disabled={props.customer}
                />
              </div>
              <div className="xgs-company__users__details__impersonate">
                <Button                    
                  theme={ButtonThemes.transparent}
                  size={ButtonSizes.auto}
                  onClick={() => setShowImpersonateConfirmation(true)}
                >
                  <div className="xgs-btn__icon-container">
                    <ViewIcon className="xgs-btn__icon" />
                  </div>
                  View as {userData.role === UserRoles.AGENT ? "agent" : "customer"}
                </Button>


              </div>
            </div>
          </div>
          <Tabs>
            <TabList>
              <Tab>General</Tab>
              <Tab disabled={UserUtils.isAgent(userData)}>Permissions</Tab>
              {UserUtils.isXGSAdministrator(userState.profile) && UserUtils.isCustomer(userData) && (
                <Tab>Change password</Tab>
              )}
            </TabList>
            <TabPanel>
              <div
                className="xgs-sidebar__details__rows"
                style={{ marginBottom: 24 }}
              >
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Email:</div>
                  <div className="xgs-sidebar__details__value">
                    <XGSInlineSwitch
                      name="email"
                      staticContent={
                        <a href={"mailto:" + userData.email} className="xgs-company__link">{ userData.email }</a>
                      }
                      formElement={
                        <XGSInput
                          type="text"
                          name="email"
                          value={email}
                          placeholder="user@example.com"
                          onChange={(e) => setEmail(e.currentTarget.value)}
                        />
                      }
                      onCancel={() => setEmail(userData.email)}
                      onSubmit={() => updateProfile({email})}
                      disabled={props.customer || userData.status === "INVITED"}
                    />
                    {userData.pendingEmail && (userData.pendingEmail !== userData.email) && (
                      <div className="xgs-company__users__details__additional__info">
                        <div className="xgs-company__users__details__additional__info__email-block">
                          Change of email address to <strong>{userData.pendingEmail}</strong> has been requested.
                        </div>
                        The user hasn't confirmed this change yet.
                      </div>
                    )}
                  </div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Phone:</div>
                  <div className="xgs-sidebar__details__value">
                    <XGSInlineSwitch
                      name="phone"
                      staticContent={userData.phoneNumber || <>(no phone)</>}
                      formElement={
                        <XGSPhoneInput
                          name="phoneNumber"
                          onValueChange={(value) => setPhoneNumber(value)}
                          onBlur={(e) => setPhoneNumber(e.currentTarget.value)}
                          value={phoneNumber}
                        />
                      }
                      onCancel={() => setPhoneNumber(userData.phoneNumber)}
                      onSubmit={() => updateProfile({phoneNumber})}
                      disabled={props.customer}
                      notes={
                        <div className="xgs-form__field__notes">
                          <strong>Note:</strong> by providing a telephone number and submitting this form you are consenting to be contacted by SMS text message.
                          Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Sign-up date:</div>
                  <div className="xgs-sidebar__details__value">{ userData.dateCreated.toUiDateFormat() }</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Status:</div>
                  <div className="xgs-sidebar__details__value flexbox">
                    <div
                      className="xgs-company__users__details__status__circle"
                      style={{
                        backgroundColor: userStatuses.find((status) => status.value === userData.status)?.color
                      }}></div>
                    <div className="xgs-company__users__details__status__name">
                      {userStatuses.find((status) => status.value === userData.status)?.name }
                      {userData.status === "INVITED" && (
                        <>
                          &nbsp;<span className="xgs-company__users__details__invited-on">on {userData.invitation?.date.toUiDateFormat()}</span>
                        </>
                      )}
                    </div>
                    <div className="xgs-company__users__details__status__actions">
                      {userData.status === "APPROVED" && !(teamUsersState.update_was_started && teamUsersState.update_creator === "status") && (
                        <>
                          <span className="xgs-company__link" onClick={() => onDisableUser()}>Disable</span>
                        </>
                      )}
                      {userData.status === "INVITED" && !(teamUsersState.update_was_started && teamUsersState.update_creator === "status") && (
                        <>
                          <div className="xgs-company__link" onClick={() => onResendInvite()}>Resend&nbsp;Invite</div>
                          <div className="xgs-company__link" onClick={() => onCancelInvite()}>Cancel&nbsp;Invite</div>
                          {(UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) && userData.invitation?.link && (
                            <div className="xgs-company__link" onClick={() => { userData.invitation?.link && copyTextToClipboard(userData.invitation.link) }}>Copy&nbsp;invitation&nbsp;link</div>
                          )}
                        </>
                      )}
                      {userData.status === "DISABLED" && !(teamUsersState.update_was_started && teamUsersState.update_creator === "status") && (
                        <>
                          <span className="xgs-company__link" onClick={() => onEnableUser()}>Enable</span>
                          {(UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) && (
                            <span
                              className="red-link"
                              onClick={() => setShowRemoveConfirmation(true)}
                            >
                              Delete&nbsp;permanently
                            </span>
                          )}
                        </>
                      )}
                      {userData.status === "TRANSFERRED" && !(teamUsersState.update_was_started && teamUsersState.update_creator === "status") && (
                        <>
                          <span className="xgs-company__link" onClick={() => setShowRemoveConfirmation(true)}>Delete</span>
                        </>
                      )}
                      {((teamUsersState.update_was_started && teamUsersState.update_creator === "status") || inviteManagementState.request_was_started) && (
                        <Loading isLoading={true} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="xgs-sidebar__details__rows"
                style={{ marginBottom: 24 }}
              >
                {props.additionalFields && (
                  <div className="xgs-sidebar__details__row">
                    <div className="xgs-sidebar__details__label">Customer name:</div>
                    <div className="xgs-sidebar__details__value">{ userData.team?.name }</div>
                  </div>
                )}
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-company__users__details__form">
                    <LabeledSelectInput
                      className="xgs-company__users__details__form__dropdown"
                      isSearchable={false}
                      label="Customer role:"
                      labelMode={LabelModes.row}
                      name="role"
                      onValueChange={(v) => setUserRole(v)}
                      options={getUserRoleOptions()}
                      required={true}
                      requiredAsteriskDisabled={true}
                      value={userRole}
                    />
                    {(userRole?.value !== userData.role) && !teamUsersState.update_was_started && (
                      <>
                        <Button
                          className="xgs-company__small-button xgs-company__users__details__form__button"
                          theme={ButtonThemes.blue}
                          disabled={userRole?.value === userData.role}
                          spinner={false}
                          onClick={() => saveRole()}>
                          <XGSIcon
                            icon={XGSIcons.faCheck}
                            size="sm"
                          />
                        </Button>
                        <Button
                          className="xgs-company__small-button xgs-company__users__details__form__button"
                          type="button"
                          theme={ButtonThemes.gray}
                          disabled={userRole?.value === userData.role}
                          spinner={false}
                          onClick={() => resetRole()}>
                          <XGSIcon
                            icon={XGSIcons.faTimes}
                            size="sm"
                          />
                        </Button>
                      </>
                    )}
                    {teamUsersState.update_was_started && teamUsersState.update_creator === "role" && (
                      <Loading
                        className="xgs-company__users__details__form__spinner"
                        isLoading={true} />
                    )}
                  </div>
                </div>
                {!UserUtils.isAgent(userData) && <>
                  <div className="xgs-sidebar__details__row">
                    <div className="xgs-company__users__details__form">
                      <LabeledSelectInput
                        isSearchable={false}
                        label="Visibility of returns:"
                        labelMode={LabelModes.row}
                        name="returnsVisibility"
                        onValueChange={(v) => setReturnsVisibility(v)}
                        options={ACCESS_LEVELS}
                        required={false}
                        requiredAsteriskDisabled={true}
                        formik={false}
                        value={returnsVisibility}
                        className="xgs-company__users__details__form__dropdown"
                      />
                      {(returnsVisibility?.value !== visibilityLevel("RETURN")) && !teamUsersState.update_was_started && (
                        <>
                          <Button
                            className="xgs-company__small-button xgs-company__users__details__form__button"
                            theme={ButtonThemes.blue}
                            disabled={returnsVisibility?.value === visibilityLevel("RETURN")}
                            spinner={false}
                            onClick={() => saveAccessLevelSettings("RETURN", returnsVisibility?.value)}>
                            <XGSIcon
                              icon={XGSIcons.faCheck}
                              size="sm"
                            />
                          </Button>
                          <Button
                            className="xgs-company__small-button xgs-company__users__details__form__button"
                            type="button"
                            theme={ButtonThemes.gray}
                            disabled={returnsVisibility?.value === visibilityLevel("RETURN")}
                            spinner={false}
                            onClick={() => resetAccessLevelSettings("RETURN")}>
                            <XGSIcon
                              icon={XGSIcons.faTimes}
                              size="sm"
                            />
                          </Button>
                        </>
                      )}
                      {teamUsersState.update_was_started && (visibilitySpinner === "RETURN") && (
                        <Loading
                          className="xgs-company__users__details__form__spinner"
                          isLoading={true}
                        />
                      )}
                    </div>
                  </div>
                  <div className="xgs-sidebar__details__row">
                    <div className="xgs-company__users__details__form">
                      <LabeledSelectInput
                        isSearchable={false}
                        label="Visibility of pickups:"
                        labelMode={LabelModes.row}
                        name="pickupsVisibility"
                        onValueChange={(v) => setPickupsVisibility(v)}
                        options={ACCESS_LEVELS}
                        required={false}
                        requiredAsteriskDisabled={true}
                        formik={false}
                        value={pickupsVisibility}
                        className="xgs-company__users__details__form__dropdown"
                      />
                      {(pickupsVisibility?.value !== visibilityLevel("PICKUP")) && !teamUsersState.update_was_started && (
                        <>
                          <Button
                            className="xgs-company__small-button xgs-company__users__details__form__button"
                            theme={ButtonThemes.blue}
                            disabled={pickupsVisibility?.value === visibilityLevel("PICKUP")}
                            spinner={false}
                            onClick={() => saveAccessLevelSettings("PICKUP", pickupsVisibility?.value)}>
                            <XGSIcon
                              icon={XGSIcons.faCheck}
                              size="sm"
                            />
                          </Button>
                          <Button
                            className="xgs-company__small-button xgs-company__users__details__form__button"
                            type="button"
                            theme={ButtonThemes.gray}
                            disabled={pickupsVisibility?.value === visibilityLevel("PICKUP")}
                            spinner={false}
                            onClick={() => resetAccessLevelSettings("PICKUP")}>
                            <XGSIcon
                              icon={XGSIcons.faTimes}
                              size="sm"
                            />
                          </Button>
                        </>
                      )}
                      {teamUsersState.update_was_started && (visibilitySpinner === "PICKUP") && (
                        <Loading
                          className="xgs-company__users__details__form__spinner"
                          isLoading={true}
                        />
                      )}
                    </div>
                  </div>
                </>}
              </div>
            </TabPanel>
            <TabPanel>
              {props.id && (
                <UserDetailsAccountsPermissions
                  userId={props.id}
                  permissions={teamUsersState.userPermissions}
                  teamId={((UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) && userData.team) ? userData.team.id : ""}
                />
              )}
            </TabPanel>
            <div className="xgs-profile__columns">
              <div className="xgs-profile__form xgs-profile__columns__item">
            {UserUtils.isXGSAdministrator(userState.profile) && UserUtils.isCustomer(userData) && (
                <TabPanel>
                  {userPasswordState.failed && (
                    <XGSErrorMessage>{userPasswordState.failReason}</XGSErrorMessage>
                  )}
                  <Formik
                    onSubmit={onPasswordSubmit}
                    initialValues={passwordInitialValues}
                    validationSchema={UserPasswordChangeFormSchema}
                    innerRef={passwordFormRef}
                  >
                    {(props: FormikProps<UserPasswordChangeForm>) => (
                      <Form className="form-items">
                        <XGSFormInput
                          type="password"
                          name="newPassword"
                          label="New password:"
                          required={true}
                          labelMode={LabelModes.column}
                        />
                        <XGSFormInput
                          type="password"
                          name="newPasswordRepeat"
                          label="Repeat new password:"
                          required={true}
                          labelMode={LabelModes.column}
                        />
                        <Button
                          theme={ButtonThemes.blue}
                          disabled={!props.isValid || !props.touched.newPassword}
                          spinner={userPasswordState.started}
                          className="update-password-btn"
                          type="submit"
                        >
                          {userPasswordState.started ? "Updating" : "Update password"}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </TabPanel>
              ) }
              </div>
            </div>
          </Tabs>
        </div>
      )}
      <ConfirmationModal
        opened={showRemoveConfirmation}
        header="Delete permanently"
        confirmButtonText="Delete user"
        spinner={teamUsersState.update_was_started && teamUsersState.update_creator === "delete"}
        onCancel={() => setShowRemoveConfirmation(false)}
        onConfirm={() => onDeleteUser()}
      >
        This operation cannot be undone,<br />
        the user will be removed permanently.
      </ConfirmationModal>
      <ConfirmationModal
        opened={showImpersonateConfirmation}
        header="View as user"
        confirmButtonText="Proceed"
        spinner={teamUsersState.update_was_started && teamUsersState.update_creator === "IMPERSONATE"}
        onCancel={() => setShowImpersonateConfirmation(false)}
        onConfirm={() => userData.role === UserRoles.AGENT ? onImpersonateAgent() : onImpersonate()}
      >
        The feature allows you to temporarily log in as a selected user.
        {userData?.status === "TRANSFERRED" && (
          <div className="xgs-company__users__details__warning">
            <XGSIcon
              icon={XGSIcons.faExclamationTriangle}
              className="xgs-company__users__details__warning__icon"
            />
            <div className="xgs-company__users__details__warning__text">
              Warning: this user is in the <strong>Pending confirmation</strong> status. 
              If you use the feature, the status will be changed to <strong>Active</strong>!
            </div>
          </div>
        )}
      </ConfirmationModal>      
    </SlideOutSidebar>
  );
};

export default UserDetails;
