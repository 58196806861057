import React, { memo } from "react";
import LabeledInput, {
  CommonLabeledInputProps,
  CommonLabeledInputElementProps,
} from "../labeledInput";
import XGSInput, { XGSInputBaseProps } from "../../../xgs-input/xgsInput";
import "./labeledTextInput.scss";

export interface LabeledTextInputProps
  extends CommonLabeledInputElementProps,
    CommonLabeledInputProps,
    React.HTMLProps<HTMLInputElement>,
    XGSInputBaseProps {
  isLoading?: boolean;
  hasCustomError?: boolean;
}

const LabeledTextInput: React.FC<LabeledTextInputProps> = memo(({
  label,
  required,
  labelMode,
  requiredAsteriskDisabled,
  fields,
  meta,
  error,
  hasCustomError,
  className,
  ...props
}) => {
  const isFailed = (): boolean => {
    return (meta?.touched && meta?.error) || error || hasCustomError ? true : false;
  };

  return (
    <LabeledInput
      label={label}
      labelMode={labelMode}
      required={required}
      requiredAsteriskDisabled={requiredAsteriskDisabled}
      isFailed={isFailed}
      error={meta?.error || error}
      className={className || ''}
    >
      <XGSInput
        isFailed={isFailed}
        {...fields}
        {...props}
      />
    </LabeledInput>
  );
});

export default LabeledTextInput;
