import { IState} from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { ServiceDisruptionProbillsState } from "./serviceDisruptionState";
import ServiceDisruptionService from "../../../app/data/service-disruption/serviceDisruptionService";

const initialState: ServiceDisruptionProbillsState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  probills: []
};

const serviceDisruptionService = ServiceDisruptionService.getInstance();

export const serviceDisruptionProbillsSlice = createSlice({
  name: "serviceDisruptionProbills",
  initialState,
  reducers: {
    resetServiceDisruptionProbills: (state) => initialState,
    serviceDisruptionProbillsLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    serviceDisruptionProbillsLoadingSucceed: (state, { payload }) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.probills = payload.probills;
    },
    serviceDisruptionProbillsLoadingFailed: (state) => {
      state.loading = false;
      state.loadingFailed = true;
      state.probills = [];
    },
  },
});

export const {
  resetServiceDisruptionProbills,
  serviceDisruptionProbillsLoadingStarted,
  serviceDisruptionProbillsLoadingSucceed,
  serviceDisruptionProbillsLoadingFailed
} = serviceDisruptionProbillsSlice.actions;

export const serviceDisruptionProbillsSelector = (state: IState) => state.serviceDisruptionProbills;

export const getServiceDisruptionProbills = (
  terminalNumber: number,
  onError?: (error: string) => void
): AppThunk => async (dispatch) => {
  if (!terminalNumber) {
    dispatch(serviceDisruptionProbillsLoadingFailed());
    return;
  }
  dispatch(serviceDisruptionProbillsLoadingStarted());
  const response = await serviceDisruptionService.getServiceDisruptionProbills({terminalNumber});
  if (response.ok() && response.data && response.data.probills) {
    dispatch(serviceDisruptionProbillsLoadingSucceed(response.data));
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    dispatch(serviceDisruptionProbillsLoadingFailed());
    onError && onError(error)
  }
};

const serviceDisruptionProbillsReducer = serviceDisruptionProbillsSlice.reducer;
export default serviceDisruptionProbillsReducer;