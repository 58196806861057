
export enum DropStatus {
  DISPATCHED = "DISPATCHED",
  LOADED = "LOADED",
  IN_PROGRESS = "IN_PROGRESS"
};

export const DropStatusHumanFriendly: { [key: string]: string } = {
  [DropStatus.DISPATCHED]: "Dispatched",
  [DropStatus.LOADED]: "Loaded",
  [DropStatus.IN_PROGRESS]: "In Progress"
};

export enum ProbillStatus {
  DISPATCHED = "DISPATCHED",
  LOADED = "LOADED",
  IN_PROGRESS = "IN_PROGRESS"
};

export const ProbillStatusHumanFriendly: { [key: string]: string } = {
  [ProbillStatus.DISPATCHED]: "Dispatched",
  [ProbillStatus.LOADED]: "Loaded",
  [ProbillStatus.IN_PROGRESS]: "In Progress"
};
