import React, { memo, useRef, useState } from "react";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import { useSelector, useDispatch } from "react-redux";
import SlideOutSidebar from "../../../../ui-components/slide-out-sidebar/slideOutSidebar";
import { Form, Formik } from "formik";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledDateInput from "../../../../ui-components/form/date/xgsFormDate";
import { toast } from "react-toastify";
import { CreateLateReasonsState, ServiceDisruptionSelectedProbillsState } from "../../../../slices/dispatch-planning/service-disruption/serviceDisruptionState";
import { serviceDisruptionSelectedProbillsSelector } from "../../../../slices/dispatch-planning/service-disruption/serviceDisruptionSelectedProbillsSlice";
import XGSFormTextarea from "../../../../ui-components/form/textarea/xgsFormTextarea";
import { ServiceDisruptionRequestSchema } from "../../../../app/data/service-disruption/models";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import { createLateReasonsForProbills, createLateReasonsSelector, resetCreateLateReasons } from "../../../../slices/dispatch-planning/service-disruption/createLateReasonsSlice";
import { CreateReasonsProgress } from "./create-reasons-progress/createReasonsProgress";
import moment from "moment";
import UserState from "../../../../slices/user/UserState";
import { userSelector } from "../../../../slices/user/userSlice";
import "./createLateReasons.scss";

const initialFormValues = {
  expectedWeatherClearDate: "",
  notes: ""
}

const CreateLateReasons: React.FC = memo(
  () => {
    const [showSlideOut, setShowSlidOut] = useState(false);
    const [showCreateReasonsProgress, setShowCreateReasonsProgress] = useState<boolean>(false);
    const dispatch = useDispatch();
    const serviceDisruptionSelectedProbillsState: ServiceDisruptionSelectedProbillsState = useSelector(
      serviceDisruptionSelectedProbillsSelector
    );
    const createLateReasonsState: CreateLateReasonsState = useSelector(
      createLateReasonsSelector
    );
    const userState: UserState = useSelector(
      userSelector
    );
    const formRef = useRef<any>(null);

    const showCreateLateReasons = () => {
      formRef.current?.resetForm();
      dispatch(resetCreateLateReasons());
      setShowSlidOut(true);
    }

    const onSubmit = (values: any) => {
      const userTerminal = userState.activeTerminal?.id;
      if (!userTerminal) {
        toast.error("No terminal assigned, please contact XGS Admin.");
        return;
      }
      dispatch(resetCreateLateReasons());
      dispatch(createLateReasonsForProbills(
        userTerminal,
        {
          expectedWeatherClearDate: values.expectedWeatherClearDate.toApiDateFormat(),
          notes: values.notes
        },
        serviceDisruptionSelectedProbillsState.selectedProbills,
        () => { toast.info("Successfully created late reasons for all selected probills") },
        (error) => { toast.error(error) }
      ));
      setShowCreateReasonsProgress(true);
    };

    return (
      <div className="create-late-reasons">
        {!((!serviceDisruptionSelectedProbillsState.selectedProbills === undefined) || (Array.isArray(serviceDisruptionSelectedProbillsState.selectedProbills) && serviceDisruptionSelectedProbillsState.selectedProbills.length === 0)) &&
          <div className="selected-probills-summary">
            <div>Create Late Reasons:</div>
            <div><span className="selected-probills-summary__titile">Selected Probills: </span>{serviceDisruptionSelectedProbillsState.selectedProbills?.length}</div>
            <div><Button
              theme={ButtonThemes.blue}
              onClick={showCreateLateReasons}
              disabled={(!serviceDisruptionSelectedProbillsState.selectedProbills === undefined) || (Array.isArray(serviceDisruptionSelectedProbillsState.selectedProbills) && serviceDisruptionSelectedProbillsState.selectedProbills.length === 0)}
            >
              {"Set Delay"}
            </Button>
            </div>
          </div>
        }
        <div>
          <SlideOutSidebar
            header="Create Late Reasons"
            onClose={() => {
              if (createLateReasonsState.loading) {
                toast.warning("Please wait while the late reasons are being set");
                return;
              } else {
                setShowSlidOut(false);
                setShowCreateReasonsProgress(false);
                dispatch(resetCreateLateReasons());
              }
            }}
            show={showSlideOut}
            spinner={false}
            size="small"
          >
            <Formik
              validationSchema={ServiceDisruptionRequestSchema}
              onSubmit={onSubmit}
              initialValues={initialFormValues}
              enableReinitialize
              innerRef={formRef}
            >
              {(props) => (
                <Form>
                  <div className="weather-clear-date">
                    <LabeledDateInput
                      className="weather-clear-date__input"
                      required={true}
                      placeholderText="Select Date"
                      name={"expectedWeatherClearDate"}
                      minDate={moment().toDate()}
                      maxDate={moment().add(1, "month").toDate()}
                      disabled={false}
                      labelMode={LabelModes.column}
                      label="Expected weather clear date"
                      onDateChange={(value) => {
                        props.setFieldValue("expectedWeatherClearDate", value);
                      }}
                      onChange={() => null}
                    />
                    <div
                      className="weather-clear-date__tip"
                    >
                      <span className="weather-clear-date__tip__icon">
                        <XGSIcon icon={XGSIcons.faLightbulb}>
                        </XGSIcon>
                      </span>
                      Please provide the date when you expect the weather conditions to clear. The ETA for the probills will automatically be set to the next available service day following this date.
                    </div>
                  </div>
                  <XGSFormTextarea
                    name="notes"
                    maxLength={250}
                    label="Notes:"
                    counter={250}
                  />
                  <div className="submit-button">
                    <Button
                      spinner={createLateReasonsState.loading}
                      disabled={!props.isValid || createLateReasonsState.loading}
                      type="submit"
                      theme={ButtonThemes.blue}
                      className="submit-button"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            {showCreateReasonsProgress &&
              <CreateReasonsProgress />
            }
          </SlideOutSidebar>
        </div>
      </div>
    );
  }
);

export default CreateLateReasons;
