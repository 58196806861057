import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserState from "../slices/user/UserState";
import { useSelector } from "react-redux";
import { userSelector } from "../slices/user/userSlice";

export const useSearchParam = <Key extends string>(path: string, paramName: Key) => {
  const params = useParams() as { [x in Key]: string };
  const [param, setParam] = useState<number | string>();
  const userState: UserState = useSelector(userSelector);

  const getPath = () => {
    const accountPath = userState.activeSubAccount?.accountNumber ? `/${userState.activeSubAccount?.accountNumber}` : "";
    const terminalPath = userState?.activeTerminal?.id ? `/${userState.activeTerminal?.id}` : "";

    return accountPath + terminalPath +  path;
  }

  const setSearchParam = (id: number | string) => {
    setParam(id);
    window.history.replaceState("", "", getPath() + "/" + id);
  };

  const resetSearchParam = () => {
    setParam(undefined);
    window.history.replaceState("", "", getPath());
  };

  useEffect(() => {
    if (!params[paramName]) return;
    setParam(params[paramName]);
  }, [params, paramName]);

  return { param, setSearchParam, resetSearchParam };
}