import * as Yup from "yup";
import { AddressModel } from "../common/address";
import { PageResponseModel } from "../common/models";
import { PickupStatus } from "../common/route";
import { validationMessages } from "../common/validationMessages";

export interface CustomerPickupsFilterModel {
  pickupNumber?: string,
  dateFrom?: string,
  dateTo?: string,
  statuses?: PickupStatus[] | null,
}

export interface CustomerPickup {
  pickupId: string;
  id: string;
  pickupNumber: string,
  creatorType: string,
  pickupStatus: PickupStatus,
  pickupDate: string,
  pickupReadyTime: string,
  pickupCloseTime: string,
  shipper: {
    address: AddressModel,
    accountNumber: number,
  },
  shipperZip?: string,
}

export interface CustomerPickupsResponseModel extends PageResponseModel<CustomerPickup> {}

export const CustomerPickupsFilterSchema: Yup.ObjectSchema<CustomerPickupsFilterModel> = Yup.object({
  pickupNumber: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(10, "The value must be no more than 10 characters"),
  dateFrom: Yup.string(),
  dateTo: Yup.string(),  
}).defined();