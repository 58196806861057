import React from "react";
import "./pickupDetails.scss";
import { cn } from "../../../services/common/className";

export interface PickupDetailsBlockProps {
  header?: string;
  content: {
    label?: string | React.ReactNode;
    value: React.ReactNode;
    required?: boolean;
  }[];
  oneLine?: boolean;
};

const PickupDetailsBlock: React.FC<PickupDetailsBlockProps> = (props) => {
  return (
    <div className="xgs-pickup-details__block">
      {props.header && (
        <div className="xgs-pickup-details__block__header">
          {props.header}
        </div>
      )}
      <div className={`xgs-pickup-details__block__content ${props.oneLine ? "xgs-pickup-details__block__content--one-line" : ""}`}>
        {props.content && props.content.length > 0 && (
          <>
            {props.content.map((item, i) => (
              <>
                {(!!item.value || item.required) && (
                  <div
                    className="xgs-pickup-details__block__content__pair"
                    key={"line-" + i}
                  >
                    {item.label && (
                      <div className="xgs-pickup-details__block__content__label">
                        {typeof item.label === "string" ? `${item.label}:` : item.label}
                      </div>
                    )}
                    <div className={cn("xgs-pickup-details__block__content__value")({ missing: !item.value })}>
                      {item.value || "missing"}
                    </div>
                  </div>
                )}
              </>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default PickupDetailsBlock;
