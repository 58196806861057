import moment from "moment";
import { currentOrClosestBusinessDay } from "../../hooks/utils";
import { weekDays } from "../../ui-components/xgs-date/xgs-date/xgsDate";

const isHoliday = ( date: any, holidays?: string[]) => !!(holidays && holidays.find((holiday) => (moment(date).isSame(holiday, "day"))));

// commonState.pickupDays
export const firstServiceCenterWorkDay = (pickupDays, holidays?: string[], startFromNextday?: boolean) => {
  if (!pickupDays || Object.keys(pickupDays).length === 0) return currentOrClosestBusinessDay();
  let day = moment(); // default value to avoid TS error (possible undefined)
  for (let i = startFromNextday ? 1 : 0; i < 8; i++) {  // set i = 1 for the next day
    day = moment().add(i, "day");    
    let dayOfWeek = day.format("dd").toLowerCase();    
    if (pickupDays[dayOfWeek as keyof weekDays] === true && !isHoliday(day, holidays)) break;
  } 

  return day.format("MM/DD/YYYY");
};
