import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import useInterval from "../../hooks/useInterval";
import ContentContainer from "../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import Table from "../../ui-components/table/table";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSFormInput from "../../ui-components/form/input/xgsFormInput";
import Button, { ButtonSizes, ButtonThemes } from "../../ui-components/button/button";
import ProgressBar from "../../ui-components/progress-bar/progressBar";
import { cn } from "../../services/common/className";
import DockLoadingSummary from "./dockLoadingSummary";
import DoorDetails from "./doorDetails";
import InvolvedUsers from "./involvedUsers";
import {
  clearData,
  dockLoadingSelector,
  getDockLoadingStatus
} from "../../slices/dock-loading/dockLoadingSlice";
import { userSelector } from "../../slices/user/userSlice";
import {
  DockLoadingFilterModel,
  DockLoadingFilterSchema,
  DoorsResponseModel
} from "../../app/data/dock-loading/models";
import { showPrettyTime } from "./common";
import refreshIcon from "../../images/refresh.svg";
import personIcon from "../../images/person-line_blue.svg";
import cleanIcon from "../../images/clean_gray.svg";
import "./dockLoading.scss";

const initialFilterValues: DockLoadingFilterModel = {
  probillNumber: null,
  manifestNumber: null,
  itemNumber: "",
  doorNumber: "",
  distributorNumber: null,
  carrierNumber: null
};

const DockLoading: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const dockLoadingState = useSelector(dockLoadingSelector);
  const userState = useSelector(userSelector);
  const filterFormRef = useRef<FormikProps<DockLoadingFilterModel>>(null);
  const [filter, setFilter] = useState<DockLoadingFilterModel>(initialFilterValues);
  const [doorNumber, setDoorNumber] = useState("");
  const [manifestNumber, setManifestNumber] = useState<number | undefined>(undefined);
  const [showDoorDetails, setShowDoorDetails] = useState(false);
  const [showInvolvedUsers, setShowInvolvedUsers] = useState(false);

  const columns = [
    {
      width: 80,
      Header: "Door #",
      accessor: "doorNumber"
    },
    {
      width: 116,
      Header: "Distributor",
      accessor: "distributorName",
      Cell: ({ row }: any) => (
        <>
          {row.original.distributorName}<br/>
          <span className="xgs-dock-loading__distributor-number">{row.original.distributorNumber || ""}</span>
        </>
      )
    },
    {
      width: 80,
      minWidth: 80,
      Header: "Carrier #",
      accessor: "carrierNumber",
      Cell: (cellProps: any) => cellProps.value || ""
    },
    {
      width: 110,
      minWidth: 110,
      Header: "Loaded Pros / At Risk",
      accessor: "loadedProbillCount",
      Cell: ({ row }: any) => (
        <>
          {row.original.loadedProbillCount} / <span className={cn("xgs-dock-loading__at-risk")({ zero: row.original.atRiskProbillsCount === 0 })}>{row.original.atRiskProbillsCount}</span>
        </>
      )
    },
    {
      width: 80,
      Header: "Loaded Weight",
      accessor: "loadedWeight"
    },
    {
      width: 72,
      Header: "Pieces",
      accessor: "loadedPieces"
    },
    {
      width: 80,
      minWidth: 80,
      Header: "Trailer #",
      accessor: "trailerNumber",
      Cell: (cellProps: any) => cellProps.value || ""
    },
    {
      width: 96,
      minWidth: 96,
      Header: "Manifest #",
      accessor: "manifestNumber",
      Cell: (cellProps: any) => cellProps.value || ""
    },
    {
      width: 146,
      Header: "Last Loading",
      accessor: "lastLoadedAt",
      Cell: ({ row }: any) => (
        <div>
          {row.original.lastLoadedAt ? row.original.lastLoadedAt.toUiDateFormat() : ""}
          <span className="text-light xgs-table__cell-subitem">{moment(row.original.lastLoadedAt, "hh:mm:ss").format("h:mm A")}</span>
          {row.original.lastLoadedBy && <div>by {row.original.lastLoadedBy}</div>}
        </div>
      )
    },
    {
      width: 176,
      Header: "Loading Progress",
      accessor: "loadingProgress",
      Cell: ({ row }: any) => (
        <ProgressBar
          percentage={row.original.loadingProgressPercentage}
          additionalInfo={showPrettyTime(row.original.loadingProgressTime)}
          multicolor
        />
      )
    },
    {
      width: 96,
      Header: "Earliest ETA",
      accessor: "earliestEta",
      Cell: (cellProps: any) => (
        <>
          {cellProps.value ? cellProps.value.toUiDateFormat() : ""}
        </>
      )
    },
    {
      width: 104,
      minWidth: 100,
      Header: "EST Arrival at DST",
      accessor: "estArrivalAtDestination",
      Cell: (cellProps: any) => (
        <>
          {cellProps.value ? cellProps.value.toUiDateFormat() : ""}
        </>
      )
    }
  ];

  const terminalNumber = useMemo(() => {
    if (!userState.activeTerminal?.id) return 0;
    return userState.activeTerminal.id;
  }, [userState.activeTerminal?.id]);

  const getData = (filterValues: DockLoadingFilterModel) => {
    if (!terminalNumber) return;
    dispatch(getDockLoadingStatus(terminalNumber, filterValues, false));
  };

  const onSubmitFilter = (filterValues: DockLoadingFilterModel) => {
    setShowDoorDetails(false);
    setShowInvolvedUsers(false);
    setFilter(filterValues);
    getData(filterValues);
  };

  const clearFilter = () => {
    setFilter(initialFilterValues);
    filterFormRef.current?.resetForm();
    filterFormRef.current?.setFieldValue("probillNumber", "");
    filterFormRef.current?.setFieldValue("manifestNumber", "");
    filterFormRef.current?.setFieldValue("distributorNumber", "");
    filterFormRef.current?.setFieldValue("carrierNumber", "");
    getData(initialFilterValues);
  };

  const onDoorClick = (row: DoorsResponseModel) => {
    setDoorNumber(row.doorNumber);
    setManifestNumber(row.manifestNumber || undefined);
    setShowInvolvedUsers(false);
    setShowDoorDetails(true);
  };

  useEffect(() => {
    return () => {
      dispatch(clearData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!terminalNumber) return;
    setManifestNumber(undefined);
    setShowDoorDetails(false);
    setShowInvolvedUsers(false);
    dispatch(getDockLoadingStatus(terminalNumber, initialFilterValues, false));
  }, [terminalNumber, dispatch]);

  useInterval(() => {
    if (!terminalNumber) return;
    dispatch(getDockLoadingStatus(terminalNumber, filter, true));
  }, 6000);

  return (
      <ContentContainer
        className="xgs-list xgs-list--full-width"
        mods={{ "full-width": true }}
        titleComponent={
          <ContentContainerToolbar
            title="Dock Loading Status"
            className="xgs-dock-loading__toolbar"
            mods={{ "full-width": true }}
          >
            {!!terminalNumber && (
              <>
                <div className="xgs-dock-loading__update-notice">Information on this page updates every minute.</div>
                <Button
                  theme={ButtonThemes.transparent}
                  size={ButtonSizes.auto}
                  onClick={() => getData(filter)}
                  spinner={dockLoadingState.requestStarted && dockLoadingState.requestCreator === "GET_DOCK_LOADING"}
                >
                  {!(dockLoadingState.requestStarted && dockLoadingState.requestCreator === "GET_DOCK_LOADING") && (
                    <div className="xgs-btn__icon-container">
                      <img src={refreshIcon} className="xgs-btn__icon" alt="refresh" />
                    </div>
                  )}
                  Refresh data
                </Button>
                <Button
                  {...props}
                  theme={ButtonThemes.transparent}
                  size={ButtonSizes.auto}
                  onClick={() => setShowInvolvedUsers(true)}
                  disabled={dockLoadingState.requestStarted && dockLoadingState.requestCreator === "GET_DOCK_LOADING"}
                >
                  <div className="xgs-btn__icon-container">
                    <img src={personIcon} className="xgs-btn__icon" alt="users" />
                  </div>
                  Involved Employees
                </Button>
              </>
            )}
          </ContentContainerToolbar>
        }
      >
        <div className="xgs-dock-loading">
          {!!terminalNumber && (
            <>
              <div className="xgs-list__controls">
                <div className="xgs-list__controls__search">
                  <Formik
                    initialValues={initialFilterValues}
                    onSubmit={onSubmitFilter}
                    innerRef={filterFormRef}
                    validationSchema={DockLoadingFilterSchema}
                    enableReinitialize
                  >
                    {(props: FormikProps<DockLoadingFilterModel>) => (
                      <Form className="xgs-list__controls__search-form">
                        <XGSFormInput
                          type="text"
                          name="probillNumber"
                          label="Probill #"
                          labelMode={LabelModes.column}
                          className="xgs-item-form__field"
                        />
                        <XGSFormInput
                          type="text"
                          name="manifestNumber"
                          label="Loading Manifest #"
                          labelMode={LabelModes.column}
                          className="xgs-item-form__field"
                        />
                        <XGSFormInput
                          type="text"
                          name="itemNumber"
                          label="Item #"
                          labelMode={LabelModes.column}
                          className="xgs-item-form__field"
                          placeholder="Roll #, Pallet #, SKU"
                        />
                        <XGSFormInput
                          type="text"
                          name="doorNumber"
                          label="Door #"
                          labelMode={LabelModes.column}
                          className="xgs-item-form__field"
                        />
                        <XGSFormInput
                          type="text"
                          name="distributorNumber"
                          label="Distributor #"
                          labelMode={LabelModes.column}
                          className="xgs-item-form__field"
                        />
                        <XGSFormInput
                          type="text"
                          name="carrierNumber"
                          label="Carrier #"
                          labelMode={LabelModes.column}
                          className="xgs-item-form__field"
                        />
                        <div className="xgs-list__controls__buttons-group">
                          <Button
                            type="submit"
                            theme={ButtonThemes.blue}
                            spinner={false}
                          >
                            Search
                          </Button>
                          <Button
                            type="button"
                            theme={ButtonThemes.gray}
                            className="xgs-list__controls__clear-button"
                            onClick={clearFilter}
                            disabled={!props.dirty}
                          >
                            <img src={cleanIcon} alt="Clean" />
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="xgs-dock-loading__summary">
                <DockLoadingSummary onInvolvedEmployeesClick={() => setShowInvolvedUsers(true)} />
              </div>
              {(dockLoadingState.requestFailed && dockLoadingState.requestCreator === "GET_DOCK_LOADING") && (
                <XGSErrorMessage>{dockLoadingState.requestError}</XGSErrorMessage>
              )}
              {!(dockLoadingState.requestFailed && dockLoadingState.requestCreator === "GET_DOCK_LOADING") && (
                <div className="xgs-dock-loading__list">
                  <Table
                    isLoading={dockLoadingState.requestStarted && dockLoadingState.requestCreator === "GET_DOCK_LOADING"}
                    keepTableOnLoading
                    columns={columns}
                    data={dockLoadingState.doors}
                    rowHeight={56}
                    minTableHeight={240}
                    noResultsText="There is no data for the current terminal taking into account the search criteria"
                    responsive
                    cursorPointer={true}
                    onRowClicked={onDoorClick}
                  />
                </div>
              )}
            </>
          )}
          {!terminalNumber && (
            <XGSErrorMessage>You do not have assigned terminals. Email <a className="white-link" href="mailto:helpdesk@xgsi.com">helpdesk@xgsi.com</a> to request terminal assignment for your account.</XGSErrorMessage>
          )}
        </div>
        <DoorDetails
          show={showDoorDetails}
          onClose={() => setShowDoorDetails(false)}
          terminalNumber={terminalNumber}
          doorNumber={doorNumber}
          manifestNumber={manifestNumber}
        />
        <InvolvedUsers
          show={showInvolvedUsers}
          onClose={() => setShowInvolvedUsers(false)}
          terminalNumber={terminalNumber}
        />
      </ContentContainer>
    );
};

export default DockLoading;