import React, { useEffect, useMemo, useState } from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  matchPath,
  NavLink,
  useHistory
} from "react-router-dom";
import { cn } from "../../services/common/className";
import { FEATURE, Routes } from "../../app/route/RoutesConfig";
import UserState from "../../slices/user/UserState";
import { userSelector } from "../../slices/user/userSlice";
import { closeMobileSideMenu } from "../../slices/user/userSlice";
import { UserUtils } from "../../app/data/user/userUtils";
import SubAccountSelector from "../sub-account-selector/subAccountSelector";
import TerminalSelector from "../terminal-selector/terminalSelector";
import StageBanner from "../stage-banner/stageBanner";
import FeatureBanner from "../feature-banner/featureBanner";
import NotificationDot from "./notificationDot";
import ContactUs from "../contact-us/contactUs";
import houseIcon from "../../images/house_white.svg";
import truckIcon from "../../images/truck_white.svg";
import boxIcon from "../../images/box_white.svg";
import boxUpIcon from "../../images/box-arrow-up_white.svg";
import paperIcon from "../../images/paper_white.svg";
import papersIcon from "../../images/papers_white.svg";
import talkIcon from "../../images/talk_white.svg";
import chartIcon from "../../images/chart_white.svg";
import warehouseIcon from "../../images/warehouse_white.svg";
import megaphoneIcon from "../../images/megaphone_white.svg";
import cogwheelIcon from "../../images/cogwheel_white.svg";
import helpCenterIcon from "../../images/help-center.svg";
import aiIcon from "../../images/ai_menu.svg";
import folderIcon from "../../images/folder_white.svg";
import linehaulIcon from "../../images/linehaul_white.svg";
import "./menu.scss";

interface MenuModel {
  id: string;
  name: string;
  icon?: any;
  items: [{
    id?: string;
    name: string;
    route?: string;
    action?: () => void;
  }]
};

const Menu: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userState: UserState = useSelector(userSelector);
  const [hoverCategoryId, setHoverCategoryId] = useState("");
  const [activeCategoryId, setActiveCategoryId] = useState("");
  const [contactUsPopup, setContactUsPopup] = useState(false);

  const isPayor = useMemo(() => (userState.activeSubAccount?.id && userState.profile?.subAccounts)
    ? userState.profile.subAccounts.find(subAccount => subAccount.id === userState.activeSubAccount?.id)?.payor
    : false, [userState.activeSubAccount?.id, userState.profile?.subAccounts]);

  const customerMenuItems: MenuModel[] = useMemo(() => [
    {
      id: "home",
      name: "Home",
      icon: houseIcon,
      items: [
        {
          name: "Dashboard",
          route: Routes.home
        },
        ...(UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "RATE_QUOTE") ? [{
          name: "Rate Quotes",
          route: Routes.rates.list
        }] : []),
        {
          name: "Claims",
          route: Routes.claims.list
        },
        {
          name: "Transit Calculator",
          route: Routes.transitCalculator
        }
      ]
    },
    {
      id: "shipments",
      name: "Shipments",
      icon: truckIcon,
      items: [
        {
          name: "Track Shipments",
          route: Routes.shipments.tracking
        },
        {
          name: "Documents",
          route: Routes.shipments.documents
        }
      ]
    },
    {
      id: "bill-of-lading",
      name: "Bill of Lading",
      icon: boxIcon,
      items: [
        {
          name: "BOL",
          route: Routes.bol.list
        },
        ...(!UserUtils.hasFeatureDisabled(userState.profile, FEATURE.RETURNS) ? [{
          name: "Returns",
          route: Routes.return.list
        }] : []),
        {
          name: "Pickups",
          route: Routes.pickups.list,
        }
      ]
    },
    ...(UserUtils.isCustomer(userState.profile) ? [{
      id: "warehouse-inventory",
      name: "Warehouse",
      icon: warehouseIcon,
      items: [
        {
          name: "Summary",
          route: Routes.warehouseInventory.summary
        },
        {
          name: "Inventory",
          route: Routes.warehouseInventory.inventory
        }
      ]
    }]: []),
    ...(UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT") ? [{
      id: "aging-invoices",
      name: "Aging Invoices",
      icon: paperIcon,
      items: [
        {
          name: "Total",
          route: `${Routes.agingInvoices}/_TOTAL`
        },
        {
          name: "1-30",
          route: `${Routes.agingInvoices}/_1_30`
        },
        {
          name: "31-60",
          route: `${Routes.agingInvoices}/_31_60`
        },
        {
          name: "61-90",
          route: `${Routes.agingInvoices}/_61_90`
        },
        {
          name: "91-120",
          route: `${Routes.agingInvoices}/_91_120`
        },
        {
          name: "120+",
          route: `${Routes.agingInvoices}/_120_PLUS`
        }
      ]
    }] : []),
    ...(isPayor ? [{
      id: "reports",
      name: "Reports",
      icon: chartIcon,
      items: [
        {
          name: "Scheduled",
          route: Routes.reports.scheduled.list
        }
      ]
    }] : []),
    {
      id: "contact-us",
      name: "Contact Us",
      icon: talkIcon,
      items: [        
        {
          name: "Contacts",
          action: () => setContactUsPopup(true)
        }
      ]
    }
  ], [isPayor, userState.activeSubAccount?.id, userState.profile]);

  const employeeMenuItems: MenuModel[] = useMemo(() => [
    {
      id: "ai",
      name: "XGS AI",
      icon: aiIcon,
      items: [
        {
          name: "Shipments Chat",
          route: Routes.ai.shipmentChat,
        },
      ]
    },
    {
      id: "shipments",
      name: "Shipments",
      icon: truckIcon,
      items: [
        {
          name: "Track Shipments",
          route: Routes.shipments.tracking
        },
        {
          name: "Appointments",
          route: Routes.shipments.appointments
        },
        {
          name: "Images",
          route: Routes.deliveryRecords.list
        },
        {
          name: "Documents",
          route: Routes.shipments.documents
        }
      ]
    },
    {
      id: "pickups",
      name: "Pickups",
      icon: boxUpIcon,
      items: [
        {
          name: "Create Pickup",
          route: Routes.pickup.create
        },
        {
          name: "Pickup Assignment",
          route: Routes.pickup.management
        },
        {
          name: "Pickup Log",
          route: Routes.pickup.log
        }
      ]
    },
    {
      id: "manifests",
      name: "Manifests",
      icon: papersIcon,
      items: [
        {
          name: "Track Manifests",
          route: Routes.manifest.manifests,
        },
        {
          name: "Linehaul Mileage",
          route: Routes.linehaulMileage.mileage
        },
        ...(!UserUtils.hasFeatureDisabled(userState.profile, FEATURE.LINEHAUL_MANAGEMENT) ? [{
          name: "Lane Management",
          route: Routes.linehaulMileage.management
        }] : []),
        {
          name: "Documents",
          route: Routes.shipments.manifestDocuments
        }
      ]
    },
    {
      id: "dispatch",
      name: "Dispatch",
      icon: warehouseIcon,
      items: [
        {
          name: "Plan Route",
          route: Routes.dispatch.planRoute
        },
        {
          name: "Active Routes",
          route: Routes.dispatch.activeRoutes
        },
        {
          name: "Picklists",
          route: Routes.dispatch.picklists
        },
        {
          name: "Unplannable Probills",
          route: Routes.dispatch.unplannableProbills
        },
        {
          name: "Service Disruption",
          route: Routes.dispatch.serviceDisruption
        }
      ]
    },
    {
      id: "linehaul",
      name: "Linehaul",
      icon: linehaulIcon,
      items: [
        {
          name: "Dock Loading",
          route: Routes.dockLoading.list
        }
      ]
    },
    {
      id: "documents",
      name: "Documents",
      icon: folderIcon,
      items: [
        {
          name: "POD Recognition",
          route: Routes.documents.podRecognition
        },
        {
          name: "Recognition Logs",
          route: Routes.documents.recognitionLogs
        }
      ]
    },
    ...((UserUtils.isXGSAdministrator(userState.profile) || !UserUtils.hasFeatureDisabled(userState.profile, FEATURE.ANNOUNCEMENTS)) ? [{
      id: "notices",
      name: "Notices",
      icon: megaphoneIcon,
      items: [
        ...(!UserUtils.hasFeatureDisabled(userState.profile, FEATURE.ANNOUNCEMENTS) ? [{
          name: "Announcement",
          route: Routes.announcement
        }] : []),
        ...(UserUtils.isXGSAdministrator(userState.profile) ? [{
          name: "Feature Banner",
          route: Routes.featureBanner
        }] : []),
      ]
    }] : []),
    {
      id: "administration",
      name: "Administration",
      icon: cogwheelIcon,
      items: [
        {
          name: "Customers",
          route: Routes.customers.list
        },
        {
          name: "Agents",
          route: Routes.agents.list
        },
        {
          name: "Users",
          route: Routes.users.list
        },
        ...(UserUtils.isXGSAdministrator(userState.profile) ? [
        {
          name: "Pending Requests",
          route: Routes.customers.pending,
          id: "pending-requests"
        },
        {
          name: "Holidays",
          route: Routes.holidayCalendar
        }] : [])
      ]
    }
  ], [userState.profile]);

  const menuItems = useMemo(() => UserUtils.isCustomer(userState.profile) ? customerMenuItems : (UserUtils.isEmployee(userState.profile) ? employeeMenuItems : []), [
    userState.profile, customerMenuItems, employeeMenuItems
  ]);

  const itemRouteMatchTheCurrentPath = (
    route: string,
    match: any,
    location: any
  ): boolean => {
    if (!route) return false;
    if (match?.isExact) return true;
    route = route?.toLowerCase();
    let path = location.pathname.toLowerCase() + (location.search || "");
    path = path.replace(/^\/\d+/gm, "");
    if (path === "") path = "/";
    if (path === route) return true;
    if (route !== "/") {
      path = path.substring(0, route.length);
      return path === route;
    } else {
      // if ((path.indexOf("/aging-invoices") !== -1) || (path.indexOf("/invoices/") !== -1)) {
      //   // custom logic for the route that isn't part of the parent route
      //   return true;
      // }
      return false;
    }
  };

  useEffect(() => {
    if (activeCategoryId) return;
    let categoryId;
    for (const category of menuItems) {
      const currentCategory = category.items.find(menuItem =>
        itemRouteMatchTheCurrentPath(
          menuItem.route,
          undefined,
          window.location
        )
      );
      if (currentCategory) {
        categoryId = category.id;
        setActiveCategoryId(category.id);
        setHoverCategoryId(category.id);
        break;
      }
    }
    if (!categoryId) {
      // case for pages that do not have an item in the main menu (e.g. User Settings)
      const defaultCategoryId = UserUtils.isCustomer(userState.profile) ? "home" : "shipments";
      setActiveCategoryId(defaultCategoryId);
      setHoverCategoryId(defaultCategoryId);
    }
    // eslint-disable-next-line
  }, [menuItems, activeCategoryId]);

  useEffect(() => {
    history.listen((location) => {
      for (const category of menuItems) {
        const currentCategory = category.items.find(menuItem =>
          itemRouteMatchTheCurrentPath(
            menuItem.route,
            undefined,
            location
          ) ||
          matchPath(location.pathname, {
            path: menuItem.route,
            exact: true,
            strict: false
          }) ||
          matchPath(location.pathname, {
            path: `/:number${menuItem.route}`,
            exact: true,
            strict: false
          })
        );
        if (currentCategory) {
          setActiveCategoryId(category.id);
          setHoverCategoryId(category.id);
          break;
        }
      }
    });
    // eslint-disable-next-line
  }, [history, menuItems]);

  useEffect(() => {
    // we have no dashboard for employees, so redirect employees to Track Shipments page
    if (UserUtils.isEmployee(userState.profile) && window.location.pathname === "/") {
      window.location.replace(Routes.shipments.tracking);
    }
  }, [userState.profile]);

  return (
    <div className={`xgs-site__menu-wrapper${UserUtils.isXGSDriver(userState.profile) ? " display-none" : ""}`}>
      <div
        className="xgs-menu"
        onMouseLeave={() => setHoverCategoryId(activeCategoryId)}
      >
        <div className="xgs-menu__top-level">
          {menuItems.map((category, i) => (
            <div
              className={cn("xgs-menu__top-level__item")({
                active: activeCategoryId === category.id,
                hover: hoverCategoryId === category.id
              })}
              onMouseEnter={() => setHoverCategoryId(category.id)}
              key={`top-level-${i}`}
            >
              <img src={category.icon} alt={category.name} />
            </div>
          ))}
        </div>
        <div className="xgs-menu__second-level">
          {menuItems.filter((category) => category.id === hoverCategoryId).map((category, ci) => (
            <React.Fragment key={`second-level-${ci}`}>
              <div className="xgs-menu__second-level__header">{category.name}</div>
              {category.items.map((item, ii) => (
                <React.Fragment key={`second-level-item-${ii}`}>
                  {item.route && (
                    UserUtils.isEmployee(userState.profile)
                    || UserUtils.isAdministrator(userState.profile)
                    || item.route === "/"                    
                    || (userState.profile?.subAccounts && userState.profile.subAccounts.length > 0)) ? (
                    <NavLink
                      to={item.route}
                      className="xgs-menu__second-level__item"
                      activeClassName="xgs-menu__second-level__item--active"
                      isActive={(match, location) => itemRouteMatchTheCurrentPath(item.route, match, location)}
                      onClick={() => dispatch(closeMobileSideMenu())}
                    >
                      <div>{item.name}<NotificationDot id={item.id} /></div>
                      <div className="xgs-menu__second-level__item__arrow">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.70857 0L0.5 1.20857L5.29143 6L0.5 10.7914L1.70857 12L7.70857 6L1.70857 0Z" fill="#2F80ED" />
                        </svg>
                      </div>
                    </NavLink>
                  ) : (
                    <div className="xgs-menu__second-level__item">
                      <span onClick={() => item.action && item.action()}>{item.name}</span>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          <div className="xgs-menu__second-level__bottom-block">
            {UserUtils.isEmployee(userState.profile) && <FeatureBanner employee={true} />}
            <StageBanner />
            {UserUtils.isCustomer(userState.profile) && (
              <a href="https://support.xgsi.com/knowledge" target="_blank" rel="noopener noreferrer" className="xgs-menu__knowledge-base__link">
                <div className="xgs-menu__knowledge-base__block">
                  <img src={helpCenterIcon} className="xgs-menu__knowledge-base__icon" alt="Knowledge base" /> Help Center
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="xgs-mobile-menu">
        <StageBanner />
        {UserUtils.isEmployee(userState.profile) && <FeatureBanner employee={true} />}
        <div className="xgs-mobile-menu__selector">
          {UserUtils.isCustomer(userState.profile) && <SubAccountSelector />}
          {UserUtils.isEmployee(userState.profile) && <TerminalSelector />}
        </div>
        <div className="xgs-mobile-menu__items">
          {menuItems.map((category, ci) => (
            <React.Fragment key={`mobile-categories-${ci}`}>
              <div className="xgs-mobile-menu__items__top-level-item">
                <img src={category.icon} alt={category.name} /> {category.name}
              </div>
              {category.items.map((item, ii) => (
                <React.Fragment key={`mobile-items-${ii}`}>
                  {item.route && (
                    UserUtils.isXGSUser(userState.profile)
                    || UserUtils.isXGSAdministrator(userState.profile)
                    || UserUtils.isAdministrator(userState.profile)
                    || item.route === "/"
                    || (userState.profile?.subAccounts && userState.profile.subAccounts.length > 0)) ? (
                    <NavLink
                      to={item.route}
                      className="xgs-mobile-menu__items__second-level-item"
                      activeClassName="xgs-mobile-menu__items__second-level-item--active"
                      isActive={(match, location) => itemRouteMatchTheCurrentPath(item.route, match, location)}
                      onClick={() => dispatch(closeMobileSideMenu())}
                    >
                      <div>{item.name}<NotificationDot id={item.id} /></div>
                    </NavLink>
                  ) : (
                    <div className={`xgs-mobile-menu__items__second-level-item ${item.action ? "cursor-pointer" : ""}`}>
                      <span onClick={() => item.action && item.action()}>{item.name}</span>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          <div className="xgs-mobile-menu__second-level__bottom-block">
            {UserUtils.isCustomer(userState.profile) && (
              <a href="https://support.xgsi.com/knowledge" target="_blank" rel="noopener noreferrer" className="xgs-menu__knowledge-base__link">
                <div className="xgs-menu__knowledge-base__block">
                  <img src={helpCenterIcon} className="xgs-menu__knowledge-base__icon" alt="Knowledge base" /> Help Center
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
      <ContactUs
        onClose={() => setContactUsPopup(false)}
        showPopup={contactUsPopup}
      />
    </div>
  );
};

export default Menu;
