import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";
import { ItemModel } from "../common/models";
import { AddressModel } from "../common/address";

export interface BolShipperModel {
  shipper: number | null;
  shipperName: string;
  shipperAddress: {
    address1: string;
    city: string;
    postalCode: string;
    state: string;
  };
  bolNumber: string;
  poNumber?: string;
  payType: string;
  specialInstructions: string | undefined;
  pickupRequest?: Boolean;
  pickupDate?: string;
  pickupReadyTime: string | undefined;
  pickupCloseTime: string | undefined;
  willCall?: Boolean;
}

export const BolShipperSchema: Yup.ObjectSchema<BolShipperModel> = Yup.object({
  shipper: Yup.number().required(validationMessages.required),
  shipperName: Yup.string().required(validationMessages.required).max(36, "The value must be no more than 36 characters"),
  shipperAddress: Yup.object().shape({
    address1: Yup.string().required(validationMessages.required).max(256, "The value must be no more than 256 characters"),
    city: Yup.string().required(validationMessages.required).max(64, "The value must be no more than 64 characters"),
    postalCode: Yup.string().matches(/^\d*$/, validationMessages.notValid).min(5, "The value must be no less than 5 digits").max(5, "The value must be no more than 5 digits").required(validationMessages.required),
    state: Yup.string().required(validationMessages.required),
  }).defined(),
  bolNumber: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(20, "The value must be no more than 20 characters").required(validationMessages.required),
  poNumber: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(12, "The value must be no more than 12 characters"),
  payType: Yup.string().required(validationMessages.required),
  specialInstructions: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(150, "The value must be no more than 150 characters"),
  pickupRequest: Yup.boolean(),
  pickupDate: Yup.string()
    .when("pickupRequest", {
      is: (pickupRequest) => pickupRequest === true,
      then: Yup.string().required(validationMessages.required)
    }),
  pickupReadyTime: Yup.string()
    .when("pickupRequest", {
      is: (pickupRequest) => pickupRequest === true,
      then: Yup.string().required(validationMessages.required)
    }),
  pickupCloseTime: Yup.string()
    .when("pickupRequest", {
      is: (pickupRequest) => pickupRequest === true,
      then: Yup.string().required(validationMessages.required)
    }),
  willCall: Yup.boolean()
}).defined();

export interface BolConsigneeModel {
  name: string;
  address: AddressModel,
  phone: string;
}

export const BolConsigneeSchema: Yup.ObjectSchema<BolConsigneeModel> = Yup.object<BolConsigneeModel>({
  name: Yup.string().required(validationMessages.required).max(25, "The value must be no more than 25 characters"),
  address: Yup.object().shape({
    address1: Yup.string().required(validationMessages.required).max(256, "The value must be no more than 256 characters"),
    city: Yup.string().required(validationMessages.required).max(64, "The value must be no more than 64 characters"),
    postalCode: Yup.string().matches(/^\d*$/, validationMessages.notValid).min(5, "The value must be no less than 5 digits").max(5, "The value must be no more than 5 digits").required(validationMessages.required),
    state: Yup.string().required(validationMessages.required),
    additionalAddressLine: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(150, "The value must be no more than 150 characters"),
  }).defined(),
  phone: Yup.string().matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid).required(validationMessages.required)
}).defined();

export interface BolPayorModel {
  name: string;
  address: {
    address1: string;
    city: string;
    postalCode: string;
    state: string;
  },
  phone: string;
}

export const BolPayorSchema: Yup.ObjectSchema<BolPayorModel> = Yup.object({
  name: Yup.string().required(validationMessages.required).max(25, "The value must be no more than 25 characters"),
  address: Yup.object().shape({
    address1: Yup.string().required(validationMessages.required).max(256, "The value must be no more than 256 characters"),
    city: Yup.string().required(validationMessages.required).max(64, "The value must be no more than 64 characters"),
    postalCode: Yup.string().matches(/^\d*$/, validationMessages.notValid).min(5, "The value must be no less than 5 digits").max(5, "The value must be no more than 5 digits").required(validationMessages.required),
    state: Yup.string().required(validationMessages.required)
  }).defined(),
  phone: Yup.string().matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid).required(validationMessages.required)
}).defined();

export const BolItemSchema: Yup.ObjectSchema<ItemModel> = Yup.object({
  packageType: Yup.string().required(validationMessages.required),
  units: Yup
    .number()
    .integer("The value must be a valid amount without decimal values")
    .positive(validationMessages.positive)
    .max(9999, validationMessages.max(9999))
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  ratingClass: Yup.string().required(validationMessages.required),
  description: Yup.string().max(32, "The value must be no more than 32 characters"),
  notes: Yup.string().max(40, "The value must be no more than 40 characters"),
  yards: Yup
    .number()
    .positive(validationMessages.positive)
    .max(99999, validationMessages.max(99999))
    .typeError(validationMessages.notValid)
    .when("packageType", {
      is: "ROLL",
      then: Yup
        .number()
        .positive(validationMessages.positive)
        .max(99999, validationMessages.max(99999))
        .typeError(validationMessages.notValid)
        .required(validationMessages.required)
    })
    .when("ratingClass", {
      is: "1",
      then: Yup
        .number()
        .positive(validationMessages.positive)
        .max(99999, validationMessages.max(99999))
        .typeError(validationMessages.notValid)
        .required(validationMessages.required)
    })
    .when("ratingClass", {
      is: "3",
      then: Yup
        .number()
        .positive(validationMessages.positive)
        .max(99999, validationMessages.max(99999))
        .typeError(validationMessages.notValid)
        .required(validationMessages.required)
    }),
  weight: Yup
    .number()
    .integer("The value must be a weight without decimal values")
    .positive(validationMessages.positive)
    .max(99999, validationMessages.max(99999))
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  length: Yup
    .number()
    .positive(validationMessages.positive)
    .max(99999, validationMessages.max(99999))
    .typeError(validationMessages.notValid),
  width: Yup
    .number()
    .positive(validationMessages.positive)
    .max(99999, validationMessages.max(99999))
    .typeError(validationMessages.notValid),
  height: Yup
    .number()
    .positive(validationMessages.positive)
    .max(99999, validationMessages.max(99999))
    .typeError(validationMessages.notValid),
  diameter: Yup
    .number()
    .positive(validationMessages.positive)
    .max(99999, validationMessages.max(99999))
    .typeError(validationMessages.notValid),
  sidemark: Yup
    .string()
    .max(40, "The value must be no more than 40 characters")
}).defined();

export interface BolRequestModel {
  shipper: number;
  shipperName: string;
  shipperAddress: AddressModel;
  bolNumber: string;
  poNumber: string;
  payType: string;
  specialInstructions?: string;
  pickupDate?: string;
  pickupReadyTime?: string;
  pickupCloseTime?: string;
  willCall: Boolean;
  consignee: BolConsigneeModel,
  payor?: BolPayorModel,
  items: ItemModel[];
  quoteNumber?: number;
};

export interface BolListItemModel {
  probill: number;
  bol: string;
  po: string;
  payType: string;
  specialInstructions: string;
  shipper: number;
  consignee: {
    name: string;
    phone: string;
    address: any;
  };
  shipperInfo: {
    name: string;
    phone: string;
    address: any;
  };
  payor: any;
  dateCreated: string;
  willCall: boolean;
  pickupDate: string;
  pickupReadyTime: string;
  pickupCloseTime: string;
  pickupNumber: string;
  pickupId: string;
  pickupStatus: string;
  shipperZip: string;
  bolType: string;
  createdBy: string;
  status: {
    title: string;
    description: string;
    translate: string;
  };
  collect: boolean;
};
