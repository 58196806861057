import React, { useState, useEffect, useMemo, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import debounce from "lodash/debounce";
import { NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PathParams, TrackShipmentsPath } from "../route";
import { Routes } from "../../../../app/route/RoutesConfig";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import XGSRegularIcons from "../../../../ui-components/icon/xgsRegularIcons";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledTextInput from "../../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import LabeledMaskInput from "../../../../ui-components/molecules/labeled-inputs/labeled-mask-input/labeledMaskInput";
import LabeledDateRangeInput from "../../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import LabeledSelectInput from "../../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import SlideOutSidebar from "../../../../ui-components/slide-out-sidebar/slideOutSidebar";
import XGSCheckbox from "../../../../ui-components/xgs-checkbox/xgsCheckbox";
import ServiceCentersState from "../../../../slices/service-centers/ServiceCentersState";
import {
  getTrackShipmentsCSV,
  resetCSVErrors,
  searchShipments,
  searchTeam,
  trackShipmentSelector
} from "../../../../slices/track-shipment/trackShipmentSlice";
import TrackShipmentState from "../../../../slices/track-shipment/TrackShipmentState";
import { userSelector } from "../../../../slices/user/userSlice";
import UserState from "../../../../slices/user/UserState";
import SearchShipmentRequestModel from "../../../../app/data/tracking/SearchShipmentRequestModel";
import {
  serviceCentersSelector,
  getServiceCenters
} from "../../../../slices/service-centers/serviceCentersSlice";
import FiltersToggleState from "../../../../slices/filters-toggle/FiltersToggleState";
import { filtersToggleSelector } from "../../../../slices/filters-toggle/filtersToggleSlice";
import { getShipmentSearchSettings, setShipmentSearchSettings, shipmentSearchSettingsSelector } from "../../../../slices/general-settings/generalSettingsSlice";
import { ShipmentDropdownStatuses } from "../../../../app/data/common/ShipmentStatuses";
import { UserUtils } from "../../../../app/data/user/userUtils";
import { groups } from "./constants";
import FiltersInstructions from "./filtersInstructions";
import DownloadButton from "../../../../ui-components/download-button/downloadButton";
import XGSRadio from "../../../../ui-components/xgs-radio/xgs-radio";
import { SEARCH_LEVEL_OPTIONS } from "../../../../app/data/common/searchLevelOptions";
import { ERROR_MESSAGES } from "../../../../app/data/common/errorMessages";
import "./trackingFilter.scss";
import { AppointmentFilters } from "./appointments/appointmentFilters";
import moment from "moment";
import dateFormats from "../../../../app/data/common/dateFormats";

export interface TrackingFilterProps {
  source?: "APPOINTMENTS" | "TRACKING",
}

const TrackingFilter: React.FC<TrackingFilterProps> = memo((props) => {
  const userState: UserState = useSelector(userSelector);
  const trackShipmentState: TrackShipmentState = useSelector(trackShipmentSelector);  
  const serviceCentersState: ServiceCentersState = useSelector(serviceCentersSelector);
  const filtersToggleState: FiltersToggleState = useSelector(filtersToggleSelector);
  const shipmentSearchSettings = useSelector(shipmentSearchSettingsSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams() as PathParams;
  const [showAllFilters, setShowAllFilters] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [probills, setProbills] = useState<string>("");
  const [origin, setOrigin] = useState<XGSSelectOption | null | undefined>();
  const [destination, setDestination] = useState<XGSSelectOption | null | undefined>();
  const [status, setStatus] = useState<XGSSelectOption[] | null | undefined>();
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [accountTypes, setAccountTypes] = useState<string[] | null | undefined>(UserUtils.isEmployee(userState.profile) ? undefined : ["PAYOR"]);
  const [team, setTeam] = useState<XGSSelectOption | null | undefined>();
  const [consignee, setConsignee] = useState<string>("");
  const [linehaulManifest, setLinehaulManifest] = useState<string>("");
  const [deliveryManifest, setDeliveryManifest] = useState<string>("");
  const [showInstructions, setShowInstructions] = useState<boolean>(false);
  const [searchLevel, setSearchLevel] = useState<string | undefined>(undefined);
  const [appointmentPending, setAppointmentPending] = useState(false);
  const [etaFrom, setEtaFrom] = useState<string>();
  const [etaTo, setEtaTo] = useState<string>();
  const [consigneeHomeDepotOnly, setConsigneeHomeDepotOnly] = useState(false);
  const [textSearchTrackingNumber, setTextSearchTrackingNumber] = useState(""); // Search with wildcard matching postfix and prefix

  const currentAccountNumber = useMemo(() => {
    return userState.activeSubAccount?.accountNumber;
  }, [userState]);

  const isEmployee = useMemo(() => {
    return UserUtils.isEmployee(userState.profile);
  }, [userState]);

  const currentEmployeeTerminal = useMemo(() => {
    return userState.activeTerminal?.id;
  }, [userState.activeTerminal])

  const getDestinationTerminal = () => {
    if (props.source === "APPOINTMENTS") return currentEmployeeTerminal;
    return destination ? Number(destination.value) : (getQueryParam("d") ? Number(getQueryParam("d")) : null);
  }

  const storeSearchInUrl = (request: SearchShipmentRequestModel, requestMixin?: any) => {
    // remove empty properties
    for (const key in request) {
      if (request[key as keyof SearchShipmentRequestModel] === null
        || request[key as keyof SearchShipmentRequestModel] === undefined) delete request[key as keyof SearchShipmentRequestModel];
    }
    if (request.trackingNumber
      && (request.trackingNumber.length === 0
        || (request.trackingNumber.length === 1 && !request.trackingNumber[0]))) delete request.trackingNumber;
    if (request.status?.length === 0) delete request.status;
    // prepare query parameters
    const queryObj: any = {
      ...(request.accountNumber && {a: request.accountNumber.toString()}),
      ...(request.consignee && {c: request.consignee}),
      ...(request.destinationId && {d: request.destinationId.toString()}),
      ...(request.from && {f: request.from}),
      ...(request.to && {t: request.to}),
      ...(request.etaFrom && {ef: request.etaFrom}),
      ...(request.etaTo && {et: request.etaTo}),
      ...(request.originId && {o: request.originId.toString()}),
      ...(request.status && {s: request.status.join(",")}),
      ...(request.teamId && team?.label && {tm: `${request.teamId},${team.label}`}),
      ...(request.trackingNumber && {tn: request.trackingNumber.join(",")}),
      ...(request.linehaulManifest && {lm: request.linehaulManifest}),
      ...(request.deliveryManifest && {dm: request.deliveryManifest}),
      ...(request.consigneeHomeDepotOnly && {hdo: "yes"}),
      ...(request.appointmentPending && {ap: "yes"}),
      ...(request.textSearchTrackingNumber && {tst: request.textSearchTrackingNumber}),
      ...requestMixin
    };
    const queryString = new URLSearchParams(queryObj);
    if (request.accountTypes && request.accountTypes.length > 0) {
      queryString.append("at", request.accountTypes.join(","));
    }
    if (request.searchLevel) queryString.append("sl", request.searchLevel);
    history.push(window.location.pathname + "?" + queryString.toString());
  };

  const getQueryParam = (name: string) => {
    // not using URLSearchParams because IE11 doesn't support it
    let results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
    if (!results) return "";
    const value = results[1].toString();
    let decodedValue = "";
    try {
      decodedValue = decodeURIComponent(value) || "";
    } catch (error: any) {
      console.log(`${error.name}: ${error.message}`);
    }
    return decodedValue;
  };

  const shipmentGroup = useMemo(() => {
    if (props.source === "APPOINTMENTS") {
      return "APPOINTMENT_REQUIRED";
    } else {
      return params[TrackShipmentsPath.shipmentGroup];
    }
  }, [params, props.source]);

  const getSearchRequestModel = () => {
    let statusRequestValue = null;
    let teamRequestValue = null;
    let fromQueryValue = getQueryParam("f");
    let toQueryValue = getQueryParam("t");
    let etaFromQueryValue = getQueryParam("ef");
    let etaToQueryValue = getQueryParam("et");

    const statusStr = getQueryParam("s");
    if (statusStr) {
      const rawStatusesArr = statusStr.split(",");
      let statusesArr: XGSSelectOption[] = [];
      for (let status of rawStatusesArr) {
        const statusObj = ShipmentDropdownStatuses.find(o => o.value === status);
        statusObj && statusesArr.push(statusObj);
      }
      if (statusesArr.length > 0) {
        // setStatus(statusesArr);
        statusRequestValue = statusesArr.map((obj: XGSSelectOption) => obj.value);
      }
    } else {
      statusRequestValue = (status && status.length > 0) ? status.map((obj: XGSSelectOption) => obj.value) : null;
    }

    const teamStr = getQueryParam("tm");
    if (teamStr) {
      const rawTeamArr = teamStr.replace(/\+/g, " ").split(",");
      rawTeamArr && setTeam({
        value: rawTeamArr[0],
        label: rawTeamArr[1]
      });
      teamRequestValue = rawTeamArr[0];
    } else {
      teamRequestValue = (team && team.value) ? team.value : null
    }

    const trackingNumbers = getQueryParam("tn");
    const probillsArr = trackingNumbers
      ? trackingNumbers.split(/(?:,| )+/).map(value => encodeURIComponent(value))
      : (probills
          ? probills.split(/(?:,| )+/).map(value => encodeURIComponent(value))
          : []);

    const requestModel: SearchShipmentRequestModel = {
      accountNumber: getQueryParam("a") ? Number(getQueryParam("a")) : (accountNumber ? Number(accountNumber) : (currentAccountNumber ? currentAccountNumber : undefined)),
      ...(((accountTypes || getQueryParam("at")) && (searchLevel === "ACCOUNT" || getQueryParam("sl") === "ACCOUNT")) && {accountTypes: getQueryParam("at") ? getQueryParam("at").replace(/\+/g, " ").split(",") : accountTypes}),
      ...((consignee || getQueryParam("c")) && {consignee: getQueryParam("c") ? getQueryParam("c").replace(/\+/g, " ") : consignee}),
      ...((getDestinationTerminal() && {destinationId: getDestinationTerminal()})),
      ...((fromQueryValue || startDate) && {from: fromQueryValue ? fromQueryValue.toApiDateFormat() : (startDate ? startDate.toApiDateFormat() : undefined)}),
      ...((etaFromQueryValue || etaFrom) && {etaFrom: etaFromQueryValue ? etaFromQueryValue.toApiDateFormat() : (etaFrom ? etaFrom.toApiDateFormat() : undefined)}),
      lastIds: null,
      ...((origin || getQueryParam("o")) && {originId: origin ? Number(origin.value) : (getQueryParam("o") ? Number(getQueryParam("o")) : null)}),
      shipmentGroup,
      ...(statusRequestValue && {status: statusRequestValue}),
      ...(teamRequestValue && {teamId: teamRequestValue}),
      ...((toQueryValue || endDate) && {to: toQueryValue ? toQueryValue.toApiDateFormat() : (endDate ? endDate.toApiDateFormat() : undefined)}),
      ...((etaToQueryValue || etaTo) && {etaTo: etaToQueryValue ? etaToQueryValue.toApiDateFormat() : (etaTo ? etaTo.toApiDateFormat() : undefined)}),
      ...((probillsArr.length > 0) && {trackingNumber: probillsArr}),
      ...((linehaulManifest || getQueryParam("lm")) && {linehaulManifest: getQueryParam("lm") ? getQueryParam("lm").replace(/\+/g, " ") : linehaulManifest}),
      ...((deliveryManifest || getQueryParam("dm")) && {deliveryManifest: getQueryParam("dm") ? getQueryParam("dm").replace(/\+/g, " ") : deliveryManifest}),
      ...((searchLevel || getQueryParam("sl")) && {searchLevel: getQueryParam("sl") || searchLevel}),
      ...((consigneeHomeDepotOnly || getQueryParam("hdo")) && {consigneeHomeDepotOnly: !!getQueryParam("hdo") || !!consigneeHomeDepotOnly}),
      ...((appointmentPending || getQueryParam("ap")) && {appointmentPending: !!getQueryParam("ap") || !!appointmentPending}),
      ...((textSearchTrackingNumber || getQueryParam("tst")) && {textSearchTrackingNumber: getQueryParam("tst") ? getQueryParam("tst").replace(/\+/g, " ") : textSearchTrackingNumber})
    };
    return requestModel;
  };

  const onProbillChanged = (value: string) => {
    setProbills(value);
    const requestModel = getSearchRequestModel();
    requestModel.trackingNumber = value.split(/(?:,| )+/).map(value => encodeURIComponent(value));
    storeSearchInUrl(requestModel);
  };

  const onFullTextSearchChange = (value: string) => {
    setTextSearchTrackingNumber(value)
    const requestModel = getSearchRequestModel();
    requestModel.textSearchTrackingNumber = value;
    storeSearchInUrl(requestModel);
  }

  const onStartDateChanged = (value: string) => {
    setStartDate(value);
    const requestModel = getSearchRequestModel();
    requestModel.from = value?.toApiDateFormat();
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel);
    trackSearchEvent(requestModel);
  };

  const onEndDateChanged = (value: string) => {
    setEndDate(value);
    const requestModel = getSearchRequestModel();
    requestModel.to = value?.toApiDateFormat();
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel);
    trackSearchEvent(requestModel);
  };

  const onEtaFromChanged = (value: string) => {
    setEtaFrom(value);
    const requestModel = getSearchRequestModel();
    requestModel.etaFrom = value?.toApiDateFormat();
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel);
    trackSearchEvent(requestModel);
  };

  const onEtaToChanged = (value: string) => {
    setEtaTo(value);
    const requestModel = getSearchRequestModel();
    requestModel.etaTo = value?.toApiDateFormat();
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel);
    trackSearchEvent(requestModel);
  };

  const onOriginChanged = (_origin: XGSSelectOption | null | undefined) => {
    setOrigin(_origin);
    const requestModel = getSearchRequestModel();
    requestModel.originId = _origin ? parseInt(_origin.value, 10) : null;
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel);
    trackSearchEvent(requestModel);
  };

  const onDestinationChanged = (_destination: XGSSelectOption | null | undefined) => {
    setDestination(_destination);
    const requestModel = getSearchRequestModel();
    requestModel.destinationId = _destination
      ? parseInt(_destination.value, 10)
      : null;
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel);
    trackSearchEvent(requestModel);
  };

  const onPayorChanged = (value: string) => {
    setAccountNumber(value);
    const requestModel = getSearchRequestModel();
    requestModel.accountNumber = Number(value);
    storeSearchInUrl(requestModel);
  };

  const onConsigneeChanged = (value: string) => {
    setConsignee(value);
    setConsigneeHomeDepotOnly(false);
    const requestModel = getSearchRequestModel();
    requestModel.consignee = value;
    requestModel.consigneeHomeDepotOnly = false;
    storeSearchInUrl(requestModel);
  };

  const onStatusChanged = (status: XGSSelectOption[] | null | undefined) => {
    setStatus(status);
    const requestModel = getSearchRequestModel();
    requestModel.status = (status && status.length > 0)
      ? status.map((obj: XGSSelectOption) => obj.value)
      : null;
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel);
    trackSearchEvent(requestModel);
  };

  const onTeamChanged = (_team: XGSSelectOption | null | undefined) => {
    setTimeout(() => setTeam(_team), 50);
    const requestModel = getSearchRequestModel();
    requestModel.teamId = _team ? _team.value : null;
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel, {
      ...(_team && { tm: `${_team.value},${_team.label}` })
    });
    trackSearchEvent(requestModel);
  };

  const onAccountTypeChanged = (v: string) => {
    let newAccountTypes = [];
    if (accountTypes && accountTypes.find(accountType => accountType === v)) {
      newAccountTypes = [...accountTypes.filter(accountType => accountType !== v)];
    } else {
      newAccountTypes = accountTypes ? [...accountTypes, v] : [v];
    }
    setAccountTypes(newAccountTypes);
    const requestModel = getSearchRequestModel();
    requestModel.accountTypes = (newAccountTypes && newAccountTypes.length > 0) ? newAccountTypes : null;
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel);
    trackSearchEvent(requestModel);
  };

  const onSearchLevelChanged = (value: string) => {
    setSearchLevel(value);
    const requestModel = getSearchRequestModel();
    requestModel.searchLevel = value;
    storeSearchInUrl(requestModel);
  };

  const getOriginsOptions = () => {
    return (
      serviceCentersState.origins?.map(
        (origin): XGSSelectOption => ({
          label: origin.name,
          value: origin.id.toString(),
        })
      ) || []
    );
  };

  const getDestinationsOptions = () => {
    return (
      serviceCentersState.destinations?.map(
        (destination): XGSSelectOption => ({
          label: destination.name,
          value: destination.id.toString(),
        })
      ) || []
    );
  };

  const getStatusesOptions = () => {
    return (
      ShipmentDropdownStatuses.map(
        (status): XGSSelectOption => ({
          label: status.label,
          value: status.value,
        })
      ) || []
    );
  };

  const onDeliveryManifestChanged = (value: string) => {
    setDeliveryManifest(value);
    const requestModel = getSearchRequestModel();
    requestModel.deliveryManifest = value;
    storeSearchInUrl(requestModel);
  };

  const onLinehaulManifestChanged = (value: string) => {
    setLinehaulManifest(value);
    const requestModel = getSearchRequestModel();
    requestModel.linehaulManifest = value;
    storeSearchInUrl(requestModel);
  };

  const onConsigneeHomeDepotOnlyChanged = (value: boolean) => {
    setConsignee("");
    setConsigneeHomeDepotOnly(value);
    const requestModel = getSearchRequestModel();
    requestModel.consigneeHomeDepotOnly = value;
    requestModel.consignee = null;
    storeSearchInUrl(requestModel);
    dispatch(searchShipments(requestModel));
    trackSearchEvent(requestModel);
  }

  const onAppointmentPendingChanged = (value: boolean) => {
    setAppointmentPending(value);
    const requestModel = getSearchRequestModel();
    requestModel.appointmentPending = value;
    storeSearchInUrl(requestModel);
    dispatch(searchShipments(requestModel));
    trackSearchEvent(requestModel);
  }
  
  const search = () => {
    const searchRequest = getSearchRequestModel();
    storeSearchInUrl(searchRequest);
    dispatch(searchShipments(searchRequest));
    trackSearchEvent(searchRequest);
  };

  const clear = () => {
    setAccountNumber("");
    setConsignee("");
    setDestination(null);
    setEndDate(undefined);
    setOrigin(null);
    setProbills("");
    setStartDate(undefined);
    setStatus(null);
    setTeam(null);
    setLinehaulManifest("");
    setDeliveryManifest("");
    setConsigneeHomeDepotOnly(false);
    setAppointmentPending(false);
    setEtaFrom(undefined)
    setEtaTo(undefined);
    setTextSearchTrackingNumber("");

    setAccountTypes(!UserUtils.isEmployee(userState.profile) ? ["PAYOR"] : undefined);
    setSearchLevel(!UserUtils.isEmployee(userState.profile) ? "ACCOUNT" : undefined);
    dispatch(searchShipments({
      ...(((getDestinationTerminal() && props.source === "APPOINTMENTS") && {destinationId: getDestinationTerminal()})),
      ...(currentAccountNumber && {accountNumber: currentAccountNumber}),
      ...(!UserUtils.isEmployee(userState.profile) && {accountTypes: ["PAYOR"]}),
      shipmentGroup
    }));
    storeSearchInUrl({...(currentAccountNumber && {accountNumber: currentAccountNumber})});
  };

  const trackSearchEvent = (searchRequest: SearchShipmentRequestModel) => {
    if (UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) return;
    mixpanel.track("Searched for probills", searchRequest);
  };

  let teamSearch = (value: string) => {
    value?.length > 1 && dispatch(searchTeam(value));
  }
  teamSearch = debounce(teamSearch, 300);

  const additionalFilterName = (group: string) => {
    const groupObj = groups.find(groupObj => group === groupObj.value);
    return groupObj ? `${groupObj.parentLabel} - ${groupObj.label}` : "";
  };

  const fetchCSV = () => {
    if (trackShipmentState.CSVLink) {
      onExportSuccess(trackShipmentState.CSVLink);
    } else {
      dispatch(getTrackShipmentsCSV(getSearchRequestModel(), onExportSuccess));
    }
  };

  const onExportSuccess = (exportLink: string) => {
    if (!exportLink) return
    const link = document.createElement("a");
    link.href = exportLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const accountTypesFilter = (
    <div className="xgs-tracking-filter__controls">
      <div className="xgs-form__label"><strong>{userState.activeSubAccount ? `${userState.activeSubAccount.name} (${userState.activeSubAccount.accountNumber})` : " Current account "}</strong> role:</div>
      <div className="xgs-tracking-filter__account-types">
        <XGSCheckbox          
          name="payorAccountType"
          onChange={() => onAccountTypeChanged("PAYOR")}
          checked={accountTypes ? !!accountTypes.find(accountType => accountType === "PAYOR") : false}
        >
          Payor
        </XGSCheckbox>
        <XGSCheckbox
          name="shipperAccountType"
          onChange={() => onAccountTypeChanged("SHIPPER")}
          checked={accountTypes ? !!accountTypes.find(accountType => accountType === "SHIPPER") : false}
        >
          Shipper
        </XGSCheckbox>

        <XGSCheckbox
          name="consigneeAccountType"
          onChange={() => onAccountTypeChanged("CONSIGNEE")}
          checked={accountTypes ? !!accountTypes.find(accountType => accountType === "CONSIGNEE") : false}
        >
          Consignee
        </XGSCheckbox>
      </div>
      {(!accountTypes || accountTypes.length === 0) && (
        <div className="xgs-tracking-filter__account-types__warning">
          <XGSIcon
            icon={XGSIcons.faExclamationTriangle}
            className="xgs-tracking-filter__account-types__warning__icon"
          />
          <div className="xgs-tracking-filter__account-types__warning__text">
            You need to choose at least one role to&nbsp;get information about shipments.
          </div>
        </div>
      )}
    </div>
  );

  const baseFilters = (
    <div className="xgs-tracking-filter__controls">
      <div className="xgs-tracking-filter__controls__item">
        <LabeledTextInput
          label="Tracking number:"
          labelMode={LabelModes.column}
          className="xgs-tracking-filter__input"
          value={probills}
          onChange={(e) => onProbillChanged(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setShowAllFilters(false);
              search();
            }
          }}
          type="text"
          placeholder="Probill, PO or BOL number(s)"
        />
        <div className="xgs-form__field__notes">
          <strong>Note:</strong> use commas or spaces to search across multiple tracking numbers.
        </div>
      </div>
      <div className="xgs-tracking-filter__controls__item">
        <LabeledDateRangeInput
          label="Bill date range:"
          labelMode={LabelModes.column}
          className="xgs-tracking-filter__input"
          start={startDate}
          end={endDate}
          onStartChange={onStartDateChanged}
          onEndChange={onEndDateChanged}
        />
      </div>
      <div className="xgs-tracking-filter__controls__double-item">
        <div className="xgs-tracking-filter__controls__double-item__unit">
          <LabeledSelectInput
            onValueChange={onOriginChanged}
            value={origin}
            options={getOriginsOptions()}
            label="Origin:"
            labelMode={LabelModes.column}
            className="xgs-tracking-filter__input"
            isClearable
          />
        </div>
        {props.source !== "APPOINTMENTS" && <div className="xgs-tracking-filter__controls__double-item__unit">
          <LabeledSelectInput
            onValueChange={onDestinationChanged}
            value={destination}
            options={getDestinationsOptions()}
            label="Destination:"
            labelMode={LabelModes.column}
            className="xgs-tracking-filter__input"
            isClearable
          />
        </div>}
      </div>
      <div className="xgs-tracking-filter__controls__item xgs-tracking-filter__controls__item--last">
        <LabeledSelectInput
          onMultiValuesChange={onStatusChanged}
          value={status}
          options={getStatusesOptions()}
          label="Status:"
          labelMode={LabelModes.column}
          className="xgs-tracking-filter__input xgs-tracking-filter__input--multi"
          isMulti
          isClearable
          disabled={trackShipmentState.loading}
        />
      </div>
    </div>
  );

  const extraFilters = (
    <div className="xgs-tracking-filter__controls">
      {(UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) && (
        <>
          <div className="xgs-tracking-filter__controls__item">
            <LabeledDateRangeInput
              label="ETA range:"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
              start={etaFrom}
              end={etaTo}
              onStartChange={onEtaFromChanged}
              minDate={moment().subtract(2, "y").toDate()}
              onEndChange={onEtaToChanged}
            />
          </div>
          <div className="xgs-tracking-filter__controls__item">
            <LabeledMaskInput
              allowNegative={false}
              label="Payor's account number:"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
              onValueChange={(value) => onPayorChanged(value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setShowAllFilters(false);
                  search();
                }
              }}
              value={accountNumber}
              format="########"
            />
          </div>
          <div className="xgs-tracking-filter__controls__item">
            <LabeledTextInput
              type="text"
              label="Consignee:"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
              onChange={(e) => onConsigneeChanged(e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setShowAllFilters(false);
                  search();
                }
              }}
              placeholder="Consignee name or address"
              value={consignee}
            />
          </div>
          <div className="xgs-tracking-filter__controls__item">
            <LabeledSelectInput
              className="xgs-tracking-filter__input"
              isDisabled={false}
              isLoading={trackShipmentState.searchTeamStarted}
              label="Customer:"
              labelMode={LabelModes.column}
              onInputChange={teamSearch}
              onValueChange={onTeamChanged}
              openMenuOnClick={trackShipmentState.teams?.length > 0}
              options={trackShipmentState.teams}
              placeholder="Enter customer name..."
              value={team}
              isClearable
            />
          </div>
        </>
      )}
      <div className="xgs-tracking-filter__controls__item">
        <LabeledMaskInput
          allowNegative={false}
          label="Linehaul manifest:"
          labelMode={LabelModes.column}
          className="xgs-tracking-filter__input"
          onValueChange={onLinehaulManifestChanged}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setShowAllFilters(false);
              search();
            }
          }}
          value={linehaulManifest}
          format="######"
          placeholder="6 digits"
          allowEmptyFormatting={false}
        />
      </div>
      <div className="xgs-tracking-filter__controls__item">
        <LabeledMaskInput
          allowNegative={false}
          label="Delivery manifest:"
          labelMode={LabelModes.column}
          className="xgs-tracking-filter__input"
          onValueChange={onDeliveryManifestChanged}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setShowAllFilters(false);
              search();
            }
          }}
          value={deliveryManifest}
          format="######"
          placeholder="6 digits"
          allowEmptyFormatting={false}
        />
      </div>
      <div className="xgs-tracking-filter__controls__item">
        <LabeledTextInput
          label="Full text search:"
          labelMode={LabelModes.column}
          className="xgs-tracking-filter__input"
          placeholder="Probill, PO or BOL number"
          value={textSearchTrackingNumber}
          onChange={(e) => onFullTextSearchChange(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setShowAllFilters(false);
              search();
            }
          }}
        />
      </div>
    </div>
  );

  const showFiltersNotification = () => {
    return !!(
      startDate ||
      endDate ||
      etaFrom ||
      etaTo ||
      probills ||
      origin ||
      destination ||
      status ||
      (accountNumber && (currentAccountNumber || !shipmentGroup) && (Number(accountNumber) !== currentAccountNumber)) ||
      team ||
      consignee ||
      linehaulManifest ||
      deliveryManifest ||
      consigneeHomeDepotOnly ||
      appointmentPending ||
      (!UserUtils.isEmployee(userState.profile) && (
        !accountTypes ||
        (accountTypes &&
          (accountTypes.length === 0 ||
          accountTypes.length > 1 ||
          accountTypes[0] !== "PAYOR")
        )
      )) ||
      (!UserUtils.isEmployee(userState.profile) && searchLevel !== "ACCOUNT") ||
      textSearchTrackingNumber
    );
  };

  useEffect(() => {   
    if (!UserUtils.isEmployee(userState.profile)) {
      dispatch(getShipmentSearchSettings());
    }

    return () => {dispatch(setShipmentSearchSettings({}))};
    //eslint-disable-next-line    
  }, []);

  useEffect(() => {
    if (shipmentSearchSettings.filterValue  && !searchLevel) {
      onSearchLevelChanged(shipmentSearchSettings.filterValue);
      const requestModel = getSearchRequestModel();
      requestModel.searchLevel = shipmentSearchSettings.filterValue;
      dispatch(searchShipments(requestModel));
    }
    //eslint-disable-next-line    
  }, [shipmentSearchSettings.filterValue]);

  // Set default values for appointments
  useEffect(() => {
    const requestModel = getSearchRequestModel();
    if (props.source === "APPOINTMENTS" && !requestModel.etaFrom && !requestModel.etaTo) {
      onEtaFromChanged(moment().subtract(14, "d").format(dateFormats.apiDate));
    }
    //eslint-disable-next-line    
  }, [props.source])

  useEffect(() => {
    const requestModel = getSearchRequestModel();
    if (props.source === "APPOINTMENTS" && currentEmployeeTerminal) {
      requestModel.destinationId = currentEmployeeTerminal;
      storeSearchInUrl(requestModel);
      searchShipments(requestModel);
    }
    // eslint-disable-next-line 
  }, [props.source, currentEmployeeTerminal])

  useEffect(() => {
    if (window.location.search) {
      dispatch(searchShipments(getSearchRequestModel()));
      trackSearchEvent(getSearchRequestModel());
      getQueryParam("tn") && setProbills(getQueryParam("tn"));
      if (getQueryParam("f")) {
        let from = getQueryParam("f")?.toString();
        from && setStartDate(from.toUiDateFormat());
      }
      if (getQueryParam("t")) {
        let to = getQueryParam("t")?.toString();
        to && setEndDate(to.toUiDateFormat());
      }
      if (getQueryParam("ef")) {
        let etaFrom = getQueryParam("ef")?.toString();
        etaFrom && setEtaFrom(etaFrom.toUiDateFormat());
      }
      if (getQueryParam("et")) {
        let etaTo = getQueryParam("et")?.toString();
        etaTo && setEtaTo(etaTo.toUiDateFormat());
      }
      if (getQueryParam("s")) {
        const rawStatusesArr = getQueryParam("s").split(",");
        let statusesArr: XGSSelectOption[] = [];
        for (let status of rawStatusesArr) {
          const statusObj = ShipmentDropdownStatuses.find(o => o.value === status);
          statusObj && statusesArr.push(statusObj);
        }
        if (statusesArr.length > 0) {
          setStatus(statusesArr);
        }
      }
      if (getQueryParam("at")) {
        const accountTypesArr = getQueryParam("at").split(",");
        if (accountTypesArr.length > 0) {
          setAccountTypes(accountTypesArr);
        }
      }
      getQueryParam("c") && setConsignee(getQueryParam("c").replace(/\+/g, " "));
      getQueryParam("a") && setAccountNumber(getQueryParam("a"));
      getQueryParam("lm") && setLinehaulManifest(getQueryParam("lm"));
      getQueryParam("dm") && setDeliveryManifest(getQueryParam("dm"));
      getQueryParam("hdo") && setConsigneeHomeDepotOnly(!!getQueryParam("hdo"));
      getQueryParam("ap") && setAppointmentPending(!!getQueryParam("ap"));
      getQueryParam("sl") && setSearchLevel(getQueryParam("sl"));
    } else {
      dispatch(searchShipments({
        ...(currentAccountNumber && {accountNumber: currentAccountNumber}),
        ...(accountTypes && {accountTypes}),
        ...((accountNumber || params[TrackShipmentsPath.accountNumber]) && {accountNumber: Number(accountNumber) || Number(params[TrackShipmentsPath.accountNumber])}),
        ...(getDestinationTerminal() && {destinationId: getDestinationTerminal()}),
        shipmentGroup
      }));
      storeSearchInUrl({...(currentAccountNumber && {accountNumber: currentAccountNumber})});
    }
  // eslint-disable-next-line
  }, [dispatch, currentAccountNumber, shipmentGroup, ...props.source === "APPOINTMENTS" ? [currentEmployeeTerminal] : []]);

  useEffect(() => {
    if (searchLevel || isEmployee) {
      dispatch(getServiceCenters({
        accountNumber: searchLevel === 'ACCOUNT' ? currentAccountNumber : undefined,
        searchLevel,
      }));
    }
  }, [dispatch, currentAccountNumber, searchLevel, isEmployee]);

  useEffect(() => {
    // handle origin and destination in URL after loading service centers list
    if (!serviceCentersState.loaded || !window.location.search) return;
    let originObj = null;
    let destinationObj = null;
    if (getQueryParam("o")) {
      originObj = getOriginsOptions().find(o => o.value === getQueryParam("o"));
      if (originObj) setOrigin(originObj);
    }
    if (getQueryParam("d")) {
      destinationObj = getDestinationsOptions().find(o => o.value === getQueryParam("d"));
      if (destinationObj) setDestination(destinationObj);
    }
    if (getQueryParam("o") || getQueryParam("d")) {
      dispatch(searchShipments({
        ...getSearchRequestModel(),
        ...(destinationObj && {destinationId: parseInt(destinationObj.value, 10)}),
        ...(originObj && {originId: parseInt(originObj.value, 10)}),
      }));
      trackSearchEvent({
        ...getSearchRequestModel(),
        ...(destinationObj && {destinationId: parseInt(destinationObj.value, 10)}),
        ...(originObj && {originId: parseInt(originObj.value, 10)}),
      });
    }
  // eslint-disable-next-line
  }, [dispatch, serviceCentersState]);

  useEffect(() => {
    params[TrackShipmentsPath.accountNumber] && setAccountNumber(params[TrackShipmentsPath.accountNumber]);
  }, [params]);

  useEffect(() => {
    if (!trackShipmentState.loadingCSVFailed) return;
    toast.error(trackShipmentState.loadingCSVError || ERROR_MESSAGES.COMMON);
    dispatch(resetCSVErrors());
  }, [trackShipmentState.loadingCSVFailed, trackShipmentState.loadingCSVError, dispatch]);

  return (
    <>
      <div className={`xgs-tracking-filter ${filtersToggleState.showFilters ? "xgs-filters-toggle__filters--show" : "xgs-filters-toggle__filters--hide"}`}>
      {props.source === "APPOINTMENTS" && (
          <AppointmentFilters
            showAllFilters={() => setShowAllFilters(!showAllFilters)}
            appointmentPending={appointmentPending}
            consigneeHomeDepotOnly={consigneeHomeDepotOnly}
            etaFrom={etaFrom}
            etaTo={etaTo}
            onEtaFromChanged={onEtaFromChanged}
            onEtaToChanged={onEtaToChanged}
            onConsigneeHomeDepotOnlyChanged={onConsigneeHomeDepotOnlyChanged}
            onAppointmentPendingChanged={onAppointmentPendingChanged}
            fetchCSV={fetchCSV}
            showFiltersNotification={showFiltersNotification}
            clearFilters={clear}
          />
        )}
        {props.source === "TRACKING" && (
          <div>
            {baseFilters}
            <div className="xgs-tracking-filter__bottom-row">
              <div className="xgs-tracking-filter__buttons">
                <Button
                  theme={ButtonThemes.blue}
                  onClick={search}
                >
                  Search
                </Button>
                <Button
                  theme={ButtonThemes.gray}
                  onClick={clear}
                >
                  Clear Filters
                </Button>
              </div>
              {showFiltersNotification() && (
                <div className="xgs-tracking-filter__base-filters">
                  <XGSIcon
                    icon={XGSIcons.faExclamationCircle}
                    size="sm"
                    className="xgs-tracking-filter__base-filters__icon"
                  />
                  <div className="xgs-tracking-filter__base-filters__text">Filters applied to results below.</div>
                </div>
              )}
              <div className="xgs-tracking-filter__all-filters-button">
                <Button
                  theme={ButtonThemes.blue}
                  onClick={() => setShowAllFilters(!showAllFilters)}
                  icon={XGSIcons.faFilter}
                >
                  <span>All Filters</span>
                </Button>
              </div>
            </div>
            {shipmentGroup && (
              <div className="xgs-tracking-filter__additional-group">
                Additional filter applied: <span className="xgs-tracking-filter__additional-group__name">{additionalFilterName(shipmentGroup)}</span>&nbsp;
                (<NavLink to={Routes.shipments.tracking} className="blue-link">disable&nbsp;filters&nbsp;and&nbsp;view&nbsp;all&nbsp;shipments<span className="xgs-tracking-filter__additional-group__link-desktop-part">&nbsp;instead</span></NavLink>)
              </div>
            )}
          </div>
        )}
      </div>
      <div className="xgs-tracking-filter__download-csv">
        <DownloadButton
          title="Download CSV"
          spinner={trackShipmentState.loadingCSVStarted}
          onClick={() => {
            !trackShipmentState.loadingCSVStarted && fetchCSV();
          }}
        />
      </div>         
      <SlideOutSidebar
        header="Shipment Filters"
        onClose={() => {
          setShowAllFilters(false);
          setTimeout(() => setShowInstructions(false), 300);
        }}
        show={showAllFilters}
        spinner={false}
        className="xgs-tracking-filter__all-filters"
        size={'small'}
      >
        {!showInstructions &&
          <div className="xgs-tracking-filter__all-filters__help">
            <XGSIcon
              icon={XGSRegularIcons.faQuestionCircle}
              size="1x"
              onClick={() => setShowInstructions(!showInstructions)}
            />
          </div>
        }
        {showInstructions &&
          <FiltersInstructions
            isEmployee={UserUtils.isEmployee(userState.profile) || false}
            onClose={() => setShowInstructions(false)}
          />
        }
        {!showInstructions && (
          <>
            {!UserUtils.isEmployee(userState.profile) && (
              <>
                <div className="xgs-tracking-filter__controls">
                  <div className="xgs-tracking-filter__controls__item">
                    <div className="xgs-form__label">Search Level:</div>

                    <XGSRadio
                      name="searchLevel"
                      value={searchLevel}
                      onChange={onSearchLevelChanged}
                      options={SEARCH_LEVEL_OPTIONS}
                    />                    
                  </div>
                </div>
                <div className="xgs-tracking-filter__search-level__additional">
                  {searchLevel === "ACCOUNT" && accountTypesFilter}
                  {searchLevel === "TEAM" && (
                    <div className="xgs-tracking-filter__controls">
                      <div className="xgs-tracking-filter__controls__item">
                        <div className="xgs-form__label">The search will be performed on accounts:</div>
                        <div className="xgs-tracking-filter__account-numbers">{userState.profile?.paypointNumbers ? userState.profile.paypointNumbers.join(", ") : "N/A"}</div>
                      </div>
                    </div>
                  )}
                  {searchLevel === "GLOBAL" && (
                    <div className="xgs-tracking-filter__controls">
                      <div className="xgs-tracking-filter__controls__item">
                        <div className="xgs-form__field__notes" style={{ marginBottom: 8 }}><strong>Note:</strong> Also use the tracking number filter to search globally. Otherwise, the search query will only search for probills that belong to the current account.</div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {baseFilters}
            {extraFilters}
            <div className="xgs-tracking-filter__buttons xgs-tracking-filter__all-filters__buttons">
              <Button
                theme={ButtonThemes.blue}
                onClick={() => {
                  setShowAllFilters(false);
                  search();
                }}
              >
                Search
              </Button>
              <Button
                theme={ButtonThemes.gray}
                onClick={() => {
                  setShowAllFilters(false);
                  clear();
                }}
              >
                Clear Filters
              </Button>
            </div>
          </>
        )}
      </SlideOutSidebar>
    </>
  );
});

export default TrackingFilter;
