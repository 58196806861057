import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { ServiceDisruptionSelectedProbillsState } from "./serviceDisruptionState";

const initialState: ServiceDisruptionSelectedProbillsState = {
  selectedProbills: [],
};

export const serviceDisruptionSelectedProbillsSlice = createSlice({
  name: "dispatchSelectedProbills",
  initialState,
  reducers: {
    resetServiceDisruptionSelectedProbills: (state) => {
      state.selectedProbills = [];
    },
    setServiceDisruptionSelectedProbills: (state, { payload }) => {
      state.selectedProbills = payload;
    },
  },
});

export const { 
  resetServiceDisruptionSelectedProbills,
  setServiceDisruptionSelectedProbills
} = serviceDisruptionSelectedProbillsSlice.actions;

export const serviceDisruptionSelectedProbillsSelector = (state: IState) =>
  state.serviceDisruptionSelectedProbills;
  
const serviceDisruptionSelectedProbillsReducer = serviceDisruptionSelectedProbillsSlice.reducer;
export default serviceDisruptionSelectedProbillsReducer;
