import React from "react";
import { cn } from "../../services/common/className";
import SummaryTile, { SummaryItemModel } from "./summary-tile/summaryTile";
import "./summary.scss";

interface SummaryProps {
  mods?: {};
  mix?: string;
  data: SummaryItemModel[];
  isLoading?: boolean;
};

const Summary: React.FC<SummaryProps> = (props) => {
  return (
    <div className={cn("xgs-summary")(props.mods, props.mix)}>
      <div className="xgs-summary__content">
      {props.data.map((item: SummaryItemModel, index: number) => (
        <SummaryTile
          key={index}
          {...item}
          mods={{
            color: item.color,
            clickable: !!item.onClick
          }}
          isLoading={props.isLoading}
        />
      ))}
      </div>      
    </div>
  );
};

export default Summary;
