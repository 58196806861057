import * as Yup from "yup";

export interface ServiceDisruptionProbill {
    probillNumber: number;
    staticRouteName: string;
    consigneeNumber: number;
    consigneeName: string;
    consigneeAddress: string;
    consigneeCity: string;
    consigneeState: string;
    consigneeZip: number;
    probillWeight: number;
    probillItems: number;
    etaDate: number;
    probillStatus: string;
    lateReasonCode: string;
    lateReasonDate: number;
    lateReasonEarlyTime: number;
    lateReasonLateTime: number;
};

export interface GetServiceDisruptionProbillsRequest {
    terminalNumber: number;
}

export interface GetServiceDisruptionProbillsResponse {
    probills: ServiceDisruptionProbill[]
}

export interface ServiceDisruptionRequestModel {
    expectedWeatherClearDate: string,
    notes?: string
};

export interface CreateLateReasonBaseRequest {
    expectedWeatherClearDate: string,
    notes?: string
}

export interface CreateLateReasonRequest extends CreateLateReasonBaseRequest {
    probillNumber: number;
}

export interface CreateLateReasonResponse {
    result: boolean;
}

export const ServiceDisruptionRequestSchema: Yup.ObjectSchema<ServiceDisruptionRequestModel> = Yup.object().shape({
    expectedWeatherClearDate: Yup.string().required("This field is required"),
    notes: Yup.string()
}).defined();
