import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Switch from "react-switch";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import LabeledTextInput from "../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import {
  serviceCentersSelector,
  getServiceCenters
} from "../../../slices/service-centers/serviceCentersSlice";
import { pickupAssignmentSelector } from "../../../slices/pickup/pickupAssignmentSlice";
import { PickupStatus } from "../../../app/data/common/route";

export interface PickupListFiltersProps {
  onSetExpiredOnly: (v: boolean) => void;
  onSetStatuses: (v: PickupStatus[]) => void;
  onSetPickupSearch: (v: string) => void;
};

const defaultStatuses = [
  { label: "Missed", value: "MISSED" },
  { label: "Unassigned", value: "UNASSIGNED" },
  { label: "Assigned", value: "ASSIGNED" }
];

const PickupListFilters: React.FC<PickupListFiltersProps> = ({onSetStatuses, ...props}) => {
  const dispatch = useDispatch();
  const pickupAssignmentState = useSelector(pickupAssignmentSelector);
  const serviceCentersState = useSelector(serviceCentersSelector);
  const [statuses, setStatuses] = useState<XGSSelectOption | null | undefined>();
  const [expiredOnly, setExpiredOnly] = useState(false);
  const [pickupSearch, setPickupSearch] = useState("");

  const getStatusesOptions = () => {
    const keys = Object.keys(PickupStatus)
    const statusesOptions = [];
    keys.forEach((key, index) => {
      let label = key.toLowerCase();
      label = label.charAt(0).toUpperCase() + label.slice(1);
      statusesOptions.push({
        label: label,
        value: key.toString()
      })
    });
    return statusesOptions;
  };

  const onExpiredOnlyChange = (checked: boolean) => {
    if (checked) {
      onStatusChange(defaultStatuses);
    }
    setExpiredOnly(checked);
    props.onSetExpiredOnly(checked);
  };

  const onStatusChange = (v: any) => {
    setStatuses(v);
    let statuses = v ? v.map(option => option.value) : [];
    onSetStatuses(statuses);
  };

  const onSearch = () => {
    props.onSetPickupSearch(pickupSearch);

  };

  useEffect(() => {
    if (!serviceCentersState.loaded && !serviceCentersState.loading) dispatch(getServiceCenters());
  }, [dispatch, serviceCentersState]);

  useEffect(() => {
    onStatusChange(defaultStatuses);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="xgs-pickup-assignment__orders__filters">
      <div className="xgs-pickup-assignment__orders__filters__control">
        <div className="xgs-pickup-assignment__orders__filters__control__toggle">
          <Switch
            onChange={(checked) => onExpiredOnlyChange(checked)}
            checked={expiredOnly}
            disabled={pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS" || pickupAssignmentState.requestCreator === "GET_PICKUPS_PORTION")}
            width={32}
            height={20}
            offColor="#BDBDBD"
            onColor="#2F80ED"
            uncheckedIcon={<></>}
            checkedIcon={<></>}
          />
        </div>
        <div className="xgs-pickup-assignment__orders__filters__control__label">
          Expired Only
        </div>
      </div>
      <div className="xgs-pickup-assignment__orders__filters__control">
        <LabeledSelectInput
          onMultiValuesChange={(option) => onStatusChange(option)}
          value={statuses}
          options={getStatusesOptions()}
          label="Statuses:"
          labelMode={LabelModes.row}
          className="xgs-pickup-assignment__orders__filters__control__input xgs-pickup-assignment__orders__filters__control__input--wide"
          isClearable={false}
          isMulti
          disabled={
            (pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS" || pickupAssignmentState.requestCreator === "GET_PICKUPS_PORTION"))
            || expiredOnly
          }
        />
      </div>
      <div className="xgs-pickup-assignment__orders__filters__control">
        <LabeledTextInput
          label="Pickup/BOL #:"
          labelMode={LabelModes.row}
          className="xgs-pickup-assignment__orders__filters__control__input"
          value={pickupSearch}
          onChange={(e) => setPickupSearch(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") onSearch();
          }}
          type="text"
          placeholder="e.g. PU00012345"
          maxLength={30}
          clearable
          onClear={() => { setPickupSearch("") }}
        />
        <Button
          theme={ButtonThemes.blue}
          className="xgs-pickup-assignment__orders__filters__control__button"
          disabled={pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS" || pickupAssignmentState.requestCreator === "GET_PICKUPS_PORTION")}
          onClick={onSearch}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default PickupListFilters;
