import React, { useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./announcementTooltip.scss";
import Button, { ButtonSizes, ButtonThemes } from "../button/button";

export interface AnnouncementTooltipProps {
  id: string;
  text: string;
  children: React.ReactElement;
}

const AnnouncementTooltip: React.FC<AnnouncementTooltipProps> = ({ id, children, text }) => {
  const ref = useRef<any>();

  useEffect(() => {
    if (!localStorage.getItem(`xgs-element-announcement-${id}`)) {
      ref.current?.open();
    }
  }, [id]);

  return (
    <div className="announcement-tooltip">
      {children}
      <Popup
      className="announcement-tooltip__popup"
        ref={ref}
        trigger={<div className="announcement-tooltip__popup-trigger" />}
        offsetY={5}
        position="bottom right"        
      >
        <div className="announcement-tooltip__content">
          {text}
        </div>
        
        <Button        
          theme={ButtonThemes.whiteBlue}
          size={ButtonSizes.fullWidth}
          onClick={() => {
            localStorage.setItem(`xgs-element-announcement-${id}`, new Date().toString());
            ref.current?.close();
          }}
        >
          Got it!
        </Button>
      </Popup>
    </div>
  )
};

export default AnnouncementTooltip;