import React from "react";
import { Link } from "react-router-dom";
import { SelectColumnFilter } from "../../../ui-components/table/tableWithFilters/filters/selectColumnFilter/selectColumnFilter";
import moment from "moment";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";

const getFormattedDate = (date: number) => {
  if (!date || !(+date)) return "";
  let dateStr = date.toString();
  let formattedDate = moment(dateStr, "YYYY-MM-DD");
  return formattedDate.format("MMM D, YYYY");
}

const getFormattedConsignee = (rawValue: string) => {
  if (rawValue) {
    let splitValues = rawValue.split("|");
    if (splitValues && splitValues.length === 2) {
      return <div>{splitValues[0]} (<span>
        {splitValues[1]}
      </span>)</div>
    } else {
      return rawValue;
    }
  } else {
    return ""
  }
}

const getLateReason = (cellProps: any) => {
  if (!cellProps.value) return "";
  return <div className="cell-late-reason">
    <div className="reason-details">
      <XGSIcon icon={XGSIcons.faExclamationTriangle}></XGSIcon>
      <span className="reason-desc">{cellProps.row.original.lateReasonDescription} ({cellProps.value})</span>
    </div>
    <div className="reason-added-by">Added by: {cellProps.row.original.lateReasonAddedBy}</div>
    <div className="reason-added-at">At: {(cellProps.row.original.lateReasonAddesAt) ?  cellProps.row.original.lateReasonAddesAt : ""}</div>
  </div>
}

const getServiceDisruptionColumns = (
) => [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }: any) => (
        <div>
          <input
            type="checkbox"
            onClick={(e) => e.stopPropagation()}
            {...getToggleAllRowsSelectedProps()}
            indeterminate={undefined}
          />
        </div>
      ),
      width: 30,
      Cell: ({ row }: any) => (
        <div>
          <input
            type="checkbox"
            onClick={(e) => e.stopPropagation()}
            {...row.getToggleRowSelectedProps()}
            indeterminate={undefined}
          />
        </div>
      ),
      disableSortBy: true
    },
    {
      Header: "Probill",
      accessor: "probillNumber",
      width: 80,
      Cell: (cellProps: any) => (
        <Link
          className="xgs-table-link-cell"
          to={`/shipments/${cellProps.value}`}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          {cellProps.value}
        </Link>
      )
    },
    {
      Header: "Static Route",
      accessor: "staticRouteName",
      width: 110,
      Filter: SelectColumnFilter,
      filter: "equals"
    },
    {
      Header: "Consignee",
      accessor: "consignee",
      width: 120,
      Cell: (cellProps: any) => (
        <div>{getFormattedConsignee(cellProps.value)}</div>
      )
    },
    {
      Header: "Street Address",
      accessor: "consigneeAddress",
      width: 150
    },
    {
      Header: "City",
      accessor: "consigneeCity",
      width: 100,
      Filter: SelectColumnFilter,
      filter: "equals"
    },
    {
      Header: "Zip Code",
      accessor: "consigneeZip",
      width: 90,
      Filter: SelectColumnFilter,
      filter: "equals"
    },
    {
      Header: "Pieces",
      accessor: "probillItems",
      width: 70,
      disableFilters: true
    },
    {
      Header: "Weight",
      accessor: "probillWeight",
      width: 70,
      Cell: (cellProps: any) => (
        <>
          {cellProps.value.toLocaleString("en", { useGrouping: true })}
        </>
      ),
      disableFilters: true
    },
    {
      Header: "ETA",
      accessor: "etaDate",
      width: 100,
      Cell: (cellProps: any) => (
        <>
          {getFormattedDate(cellProps.value)}
        </>
      ),
      Filter: SelectColumnFilter,
      filter: "equals",
      filterLableFormater: getFormattedDate
    },
    {
      Header: "Status",
      accessor: "probillStatus",
      width: 130,
      Cell: (cellProps: any) => (
        <div className="cell-probill-status">
          {cellProps.value}
        </div >
      ),
      Filter: SelectColumnFilter,
      filter: "equals"
    },
    {
      Header: "Late Reason",
      accessor: "lateReasonCode",
      width: 180,
      Cell: (cellProps: any) => (
        <>{getLateReason(cellProps)}</>
      ),
      disableFilters: true,
      disableSortBy: true
    }
  ];

export default getServiceDisruptionColumns;
