import api, { ApiResponse } from "../api2";
import { CreateLateReasonRequest, CreateLateReasonResponse, GetServiceDisruptionProbillsRequest, GetServiceDisruptionProbillsResponse } from "./models";

class ServiceDisruptionService {
  private source: any;
  private static instance: ServiceDisruptionService;

  private constructor() { }

  static getInstance(): ServiceDisruptionService {
    if (!ServiceDisruptionService.instance) {
      ServiceDisruptionService.instance = new ServiceDisruptionService();
    }
    return ServiceDisruptionService.instance;
  }

  public getServiceDisruptionProbills = async (
    requestData: GetServiceDisruptionProbillsRequest
  ): Promise<ApiResponse<GetServiceDisruptionProbillsResponse>> => {
    return await api.get(
      "/tms/service/disruption/probills",
      { params: requestData }
      );
  };

  public createLateReason = async (
    requestData: CreateLateReasonRequest
  ): Promise<ApiResponse<CreateLateReasonResponse>> => {
    return await api.post(
      "/tms/service/disruption/probill/reason",
      requestData,
      {}
    );
  };

}

export default ServiceDisruptionService;