import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface DockLoadingFilterModel {
  probillNumber?: number | null;
  manifestNumber?: number | null;
  itemNumber?: string;
  doorNumber?: string;
  distributorNumber?: number | null;
  carrierNumber?: number | null;
};

export const DockLoadingFilterSchema: Yup.ObjectSchema<DockLoadingFilterModel> = Yup.object().shape({
  probillNumber: Yup.number()
    .nullable()
    .min(1, "The value must be no less than 1")
    .max(99999999, "The value must be no greater than 99999999")
    .integer("The value must be an integer")
    .typeError(validationMessages.notValid),
  manifestNumber: Yup.number()
    .nullable()
    .min(1, "The value must be no less than 1")
    .max(999999, "The value must be no more than 999999")
    .typeError(validationMessages.notValid),
  itemNumber: Yup.string()
    .max(20, "The value must be shorter than or equal to 20 characters"),
  doorNumber: Yup.string()
    .max(5, "The value must be shorter than or equal to 5 characters"),
  distributorNumber: Yup.number()
    .nullable()
    .min(1, "The value must be no less than 1")
    .max(9999999, "The value must be no greater than 9999999")
    .typeError(validationMessages.notValid),
  carrierNumber: Yup.number()
    .nullable()
    .min(1, "The value must be no less than 1")
    .max(99999999, "The value must be no greater than 99999999")
    .typeError(validationMessages.notValid)
}).defined();

export interface SummaryResponseModel {
  totalLoadedProbillCount: number;
  totalAtRiskProbillsCount: number;
  totalLoadedWeight: number;
  totalLadedPieces: number;
  numberOfDoorsLoading: number;
  numberOfInvolvedUser: number;
};

export interface DoorsResponseModel {
  doorNumber: string;
  distributorNumber: number;
  distributorName: string;
  carrierNumber: number;
  loadedProbillCount: number;
  atRiskProbillsCount: number;
  loadedWeight: number;
  loadedPieces: number;
  trailerNumber: number;
  manifestNumber: number;
  lastLoadedAt: string;
  lastLoadedBy: string;
  earliestEta: string;
  estArrivalAtDestination: string;
  loadingProgressPercentage: number;
  loadingProgressTime: number;
};

export interface DockLoadingStatusResponseModel {
  summary: SummaryResponseModel;
  doors: DoorsResponseModel[];
};

export interface DoorDetailsResponseModel {
  manifestDetails: any;
  drops: any;
};

export interface InvolvedUsersResponseModel {
  userName: string;
  loadedItemsCount: number;
  firstLoadedAt: string;
  lastLoadedAt: string;
};
