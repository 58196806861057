import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import ConfirmationModal from "../../../../ui-components/confirmation-modal/confirmationModal";
import Button, { ButtonSizes, ButtonThemes } from "../../../../ui-components/button/button";
import FreightItemCard from "../../../common/freight-item/freightItemCard";
import PickupItemForm from "./pickupItemForm";
import {
  addItem,
  clearDeletedItems,
  clearRequestError,
  createPickup,
  deleteItem,
  pickupSelector,
  updateItem
} from "../../../../slices/pickup/pickupSlice";
import { PickupRequestModel } from "../../../../app/data/pickup/models";
import { StepProps } from "../../../../app/data/common/models";
import CommonState from "../../../../slices/common/CommonState";
import { commonSelector } from "../../../../slices/common/commonSlice";

import "../../../../sass/blocks.scss";

const PickupItemsStep: React.FC<StepProps> = ({ previous, next }) => {
  const dispatch = useDispatch();

  const pickupState = useSelector(pickupSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const [showAddItemForm, setAddItemForm] = useState<boolean>(false);
  const [removeItemIndex, setRemoveItemIndex] = useState<number | undefined>(undefined);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);
  const [editItemIndex, setEditItemIndex] = useState<number | undefined>(undefined);
  const [copyItemIndex, setCopyItemIndex] = useState<number | undefined>(undefined);

  const onClickBack = () => {
    dispatch(clearDeletedItems());
    previous && previous();
  };

  const onSubmit = () => {
    if (!pickupState.shipper) return;
    dispatch(clearDeletedItems());
    let items = pickupState.items.filter((item: any) => !item.deleted);
    let request: PickupRequestModel = {
      teamId: pickupState.teamId,
      shipper: pickupState.shipper,
      ...(pickupState.bolNumber && {bolNumber: pickupState.bolNumber}),
      ...(pickupState.poNumber && {poNumber: pickupState.poNumber}),
      ...(pickupState.additionalReference && {additionalReference: pickupState.additionalReference}),
      pickupDate: pickupState.pickupDate.toApiDateFormat(),
      pickupReadyTime: pickupState.pickupReadyTime || "",
      pickupCloseTime: pickupState.pickupCloseTime || "",
      specialInstructions: pickupState.specialInstructions,
      consignee: pickupState.consignee,
      items: items.map(({deleted, ...properties}) => properties)
    };
    dispatch(createPickup(request, () => {
      toast.info("Pickup was successfully created!");
      mixpanel.track("Pickup was created");
      next?.();
    }));
  };

  const getClassLabel = (ratingClassValue: string) => {
    let ratingClass = commonState.freightClasses.find(obj => obj.value === ratingClassValue);
    return ratingClass ? ratingClass.label : ratingClassValue;
  };

  useEffect(() => {
    if (!pickupState.requestFailed || pickupState.requestCreator === "CHECK_PICKUP") return;
    toast.error(pickupState.requestError || "Error", { autoClose: 4000 });
  }, [pickupState.requestFailed, pickupState.requestError, pickupState.requestCreator]);

  useEffect(() => {
    return () => {
      dispatch(clearRequestError());
    };
  }, [dispatch]);

  return (
    <div className="xgs-items">
      <div className="xgs-bol__section">Detail Information</div>
      {pickupState.items.map((item: any, index: number) => (
        <div key={index}>
          {(index === editItemIndex) && (
            <PickupItemForm
              editMode
              itemIndex={index}
              onSubmit={(data) => {
                dispatch(updateItem({ index, data }));
                setEditItemIndex(undefined);
              }}
              onCancel={() => {
                setEditItemIndex(undefined);
              }}
            />
          )}
          {(editItemIndex === undefined || (index !== editItemIndex)) && !item.deleted && (
            <FreightItemCard
              item={item}
              onAdd={() => {
                setAddItemForm(false);
                setCopyItemIndex(undefined);
                setEditItemIndex(index);
              }}
              onRemove={() => {
                setRemoveItemIndex(index);
                setShowRemoveConfirmation(true);
              }}
              onCopy={() => {
                setCopyItemIndex(index);
                setAddItemForm(true);
              }}
              classLabel={getClassLabel(item.ratingClass)}
            />
          )}
        </div>
      ))}
      {(showAddItemForm || pickupState.items.length === 0) && (
        <PickupItemForm
          itemIndex={copyItemIndex}
          onSubmit={(data) => {
            dispatch(addItem(data));
            setAddItemForm(false);
            setCopyItemIndex(undefined);
          }}          
        />
      )}
      {!showAddItemForm &&
        (editItemIndex === undefined) &&
        (pickupState.items.length > 0) &&
        (pickupState.items.filter((item: any) => !item.deleted).length < 15) && (        
          <Button
            className="xgs-items__add"
            theme={ButtonThemes.transparent}
            size={ButtonSizes.auto}
            onClick={() => setAddItemForm(true)}
          >
            + Add Handling Unit
          </Button>        
      )}
      <div className="xgs-bol__items__limitation">
        {pickupState.items.filter((item: any) => !item.deleted).length === 0
          ? (
            <span>Up to 15 units are allowed.</span>
          )
          : (
            <span>{pickupState.items.filter((item: any) => !item.deleted).length} of 15 allowed units have been entered.</span>
          )
        }
      </div>
      <div className="xgs-pickup__buttons">
        <Button
          type="button"
          theme={ButtonThemes.gray}
          onClick={onClickBack}
        >
          Back
        </Button>
        <Button
          type="button"
          theme={ButtonThemes.blue}
          disabled={
            pickupState.items.length === 0
            || pickupState.items.filter((item: any) => !item.deleted).length === 0
            || (editItemIndex !== undefined)
            || (pickupState.requestSucceed && pickupState.requestCreator === "CREATE_PICKUP")
          }
          spinner={pickupState.requestStarted && pickupState.requestCreator === "CREATE_PICKUP"}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </div>
      <ConfirmationModal
        fitContent
        opened={showRemoveConfirmation}
        confirmButtonText="Remove"
        onCancel={() => {
          setShowRemoveConfirmation(false);
        }}
        onConfirm={() => {
          dispatch(deleteItem(removeItemIndex));
          setShowRemoveConfirmation(false);
          setRemoveItemIndex(undefined);
        }}>
        <div className="text-center">The item will be removed from the list.</div>
      </ConfirmationModal>
    </div>
  );
};

export default PickupItemsStep;
