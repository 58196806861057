import React, { ReactElement } from "react";
import { cn } from "../../../services/common/className";
import Loading from "../../loading/loading";
import "./summaryTile.scss";

export enum SummaryItemColor {
  blue = "blue",
  red = "red",
  green = "green",
  orange = "orange",
};

export interface SummaryItemModel {
  value: string | number;
  description: string;
  icon?: ReactElement | string;
  color?: SummaryItemColor;
  onClick?: () => void;
};

interface SummaryProps extends SummaryItemModel {
  mods?: {};
  mix?: string;
  isLoading?: boolean;
};

const SummaryTile: React.FC<SummaryProps> = (props) => {
  return (
    <div
      className={cn("xgs-summary__tile")(props.mods, props.mix)}
      onClick={props.onClick && props.onClick()}
    >
      {props.icon && <div className="xgs-summary__tile__icon">{props.icon}</div>}
      <div className="xgs-summary__tile__content">
        <div className="xgs-summary__tile__value">
          {props.isLoading ? <Loading isLoading={true} /> : props.value}
        </div>
        <div className="xgs-summary__tile__description">
          {props.description}
        </div>
      </div>
    </div>
  );
};

export default SummaryTile;