import React from "react";
import { useSelector } from "react-redux";
import { CreateLateReasonsState } from "../../../../../slices/dispatch-planning/service-disruption/serviceDisruptionState";
import { createLateReasonsSelector } from "../../../../../slices/dispatch-planning/service-disruption/createLateReasonsSlice";
import Loading from "../../../../../ui-components/loading/loading";
import { ServiceDisruptionProbill } from "../../../../../app/data/service-disruption/models";
import XGSIcon from "../../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../../ui-components/icon/xgsIcons";
import "./createReasonsProgress.scss";

export const CreateReasonsProgress: React.FC = () => {
    const createLateReasonsState: CreateLateReasonsState = useSelector(
        createLateReasonsSelector
    );

    let loadingProgress = 0;
    if (createLateReasonsState.totalTasks && createLateReasonsState.totalTasks > 0 && createLateReasonsState.completedTasks) {
        loadingProgress = Math.floor((createLateReasonsState.completedTasks / createLateReasonsState.totalTasks) * 100)
    }
    return (
        <div className="create-reasons">
            <div className="create-reasons-porgress-bar__container">
                <span className="create-reasons-porgress-bar__info">
                    <span className="create-reasons-porgress-bar__progress-percentage">{loadingProgress}%</span>
                    <span className="create-reasons-porgress-bar__progress">
                        {createLateReasonsState.completedTasks}/{createLateReasonsState.totalTasks} Completed
                    </span>
                </span>
                <div className={"create-reasons-porgress-bar__bar" + (loadingProgress === 100 ? " --completed" : "")}>
                    <div style={{ width: loadingProgress + "%" }}></div>
                </div>
            </div>
            <div className="create-reasons-status">
                {createLateReasonsState.loading && <div className="create-reasons-status__loading">
                    <Loading isLoading={true}></Loading> Setting up late reasons
                </div>}
                {createLateReasonsState.loaded && <div className="create-reasons-status__loaded">
                <XGSIcon 
                    icon={XGSIcons.faCheckCircle}
                    className="create-reasons-status__loaded__icon"
                    ></XGSIcon>Completed the late reason creation for the selected probills
                </div>}
                {createLateReasonsState.loadingFailed && <div className="create-reasons-status__failed">
                    <XGSIcon 
                    icon={XGSIcons.faExclamationTriangle}
                    className="create-reasons-status__failed__icon"
                    ></XGSIcon>{`Failed to create late reasons for ${createLateReasonsState.failedTasks} probill${(createLateReasonsState.failedTasks > 1) ? "s" : ""}:`}
                    <ol className="create-reasons-status__failed__probills-list">
                        {createLateReasonsState.failedProbills.map((probill: ServiceDisruptionProbill) => <li
                            key={`fcr_${probill.probillNumber}`}
                        >{probill.probillNumber}</li>)}
                    </ol>
                </div>}
            </div>
        </div>
    )
};