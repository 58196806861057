import React from "react";
import rollIcon from "../../../images/roll_blue.svg";
import palleteIcon from "../../../images/pallete_blue.svg";
import { ReactComponent as CopyIcon } from "../../../images/copy.svg";
import { ItemModel } from "../../../app/data/common/models";
import Button, { ButtonSizes, ButtonThemes } from "../../../ui-components/button/button";
import CommonState from "../../../slices/common/CommonState";
import { useSelector } from "react-redux";
import { commonSelector } from "../../../slices/common/commonSlice";

interface FreightItemCardProps {
  item: ItemModel;
  onAdd: () => void;
  onRemove: () => void;
  onCopy: () => void;
  classLabel: string;
}

const FreightItemCard: React.FC<FreightItemCardProps> = ({ item, onAdd, onRemove, onCopy, classLabel }) => {
  const commonState: CommonState = useSelector(commonSelector);

  const getCommodityLabel = (commodityClassValue: string) => {
    let commodityClass = commonState.commodityCodes.find(obj => obj.value === commodityClassValue);
    return commodityClass ? commodityClass.label : commodityClassValue;
  };

  return (
    <div className="xgs-items__item">
      <div className="xgs-items__item__columns xgs-items__item__columns--space-between">
        <div className="xgs-items__item__columns xgs-items__item__columns--no-margin xgs-items__item__columns--stretch">
          <div className="xgs-items__item__icon">
            {item.packageType === "ROLL" && (
              <img
                src={rollIcon}
                alt="roll"
              />
            )}
            {item.packageType === "PALLET" && (
              <img
                src={palleteIcon}
                alt="pallet"
              />
            )}
          </div>
          <div className="xgs-items__item__flex-center-area">
            <div className="xgs-items__item__type">
              {item.packageType === "ROLL" && "1 Roll"}
              {item.packageType === "PALLET" && "1 Pallet"}
            </div>
            {item.ratingClass && (<div className="xgs-items__item__property xgs-items__item__class">Class: <span>{classLabel}</span></div>)}
          </div>
        </div>
        <div className="xgs-items__item__columns xgs-items__item__columns--no-margin xgs-items__item__columns--actions">          
          <div
            className="xgs-items__item__action"
            onClick={onCopy}
          >
            <CopyIcon/>
            Copy
          </div>
        </div>
      </div>
      <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
        {item.packageType === "PALLET" && <div className="xgs-items__item__property">Pieces on Pallet: <span>{item.units}</span></div>}
        <div className="xgs-items__item__property">Weight: <span>{item.weight}</span> lbs.</div>
        {!!(item.length && item.width && item.height) && (
          <div className="xgs-items__item__property">Size: <span>{item.length}</span> x <span>{item.width}</span> x <span>{item.height}</span> in</div>
        )}
        {(item.packageType === "ROLL" && item.width) && (
          <div className="xgs-items__item__property">Width: <span>{item.width}</span> in</div>
        )}
        {(item.packageType === "ROLL" && item.diameter) && (
          <div className="xgs-items__item__property">Diameter: <span>{item.diameter}</span> in</div>
        )}
      </div>
      
      {item.commodityCode && (
        <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
          <div className="xgs-items__item__property">Commodity: <span>{getCommodityLabel(item.commodityCode)}</span></div>
        </div>
      )}

      {item.description && (
        <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
          <div className="xgs-items__item__property">Description: <span>{item.description}</span></div>
        </div>
      )}
      {item.sidemark && (
        <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
          <div className="xgs-items__item__property">Sidemark: <span>{item.sidemark}</span></div>
        </div>
      )}
      {item.notes && (
        <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
          <div className="xgs-items__item__property">Notes: <span>{item.notes}</span></div>
        </div>
      )}

      <div className="xgs-items__item__columns">
        <Button
          theme={ButtonThemes.blueInverted}
          size={ButtonSizes.fullWidth}
          onClick={onAdd}
        >
          Edit
        </Button>

        <Button
          theme={ButtonThemes.redInverted}
          size={ButtonSizes.fullWidth}
          onClick={onRemove}
        >
          Remove
        </Button>
      </div>
    </div>
  );
}

export default FreightItemCard;