import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormTextarea from "../../../ui-components/form/textarea/xgsFormTextarea";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import Button, { ButtonSizes, ButtonThemes } from "../../../ui-components/button/button";
import CommonState from "../../../slices/common/CommonState";
import { commonSelector } from "../../../slices/common/commonSlice";
import "../../../sass/forms.scss";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import { ReactComponent as InfoIcon } from "../../../images/info.svg";
import XGSPopup, { PopupTheme } from "../../../ui-components/xgs-popup/xgsPopup";
import measuring from "../../../images/measuring.png";
import { ItemModel } from "../../../app/data/common/models";

interface FreightItemFormFieldsProps {
  forCustomer?: boolean;
  quoteNumber?: number;
}

const FreightItemFormFields: React.FC<FreightItemFormFieldsProps> = (props) => {
  const { setFieldValue, values, handleChange } = useFormikContext<ItemModel>();
  const commonState: CommonState = useSelector(commonSelector);
  const [guidelinesOpen, setGuidelinesOpen] = useState<boolean>(false);

  return (
    <div className="xgs-item-form__fields">
      <div className="xgs-item-form__radio-group">
        <div className="xgs-form__label">Package type:</div>
        <div className="xgs-form__radio">
          <label>
            <input
              type="radio"
              name="packageType"
              value="ROLL"
              checked={values.packageType === "ROLL"}
              onChange={(e) => {
                setTimeout(() => {
                  setFieldValue("packageType", "ROLL");
                  setFieldValue("height", "");
                  setFieldValue("length", "");
                  setFieldValue("units", "1");
                  setFieldValue("commodityCode", undefined);
                }, 100);
                handleChange(e);
              }}
            /> 1 Roll
          </label>
          <label>
            <input
              type="radio"
              name="packageType"
              value="PALLET"
              checked={values.packageType === "PALLET"}
              onChange={(e) => {
                setTimeout(() => {
                  setFieldValue("packageType", "PALLET")
                  setFieldValue("yards", "");
                  setFieldValue("units", "");
                  setFieldValue("diameter", "");
                  if (values.ratingClass === "1" || values.ratingClass === "3") {
                    setFieldValue("ratingClass", "");
                  }
                }, 100);
                handleChange(e);
              }}
            /> 1 Pallet
          </label>
        </div>
      </div>

      {values.packageType === "ROLL" && (
        <div className="xgs-item-form__row">
          <Button
            type="button"
            theme={ButtonThemes.transparent}
            size={ButtonSizes.auto}
            onClick={() => { setGuidelinesOpen(true) }}
          >
            <div className="xgs-btn__icon-container">
              <InfoIcon className="xgs-btn__icon" />
            </div>
            Measuring Guidelines
          </Button>

          <XGSPopup
            opened={guidelinesOpen}
            onClose={() => { setGuidelinesOpen(false) }}
            title="Measuring Guideline"
            theme={PopupTheme.NEW}
            className="xgs-item-form__guidelines"
          >
            <div>
              <div>
                Please use the following dimensions to accurately complete the web form.
              </div>

              <img src={measuring} alt="Measuring Guideline" />

              <Button
                type="button"
                theme={ButtonThemes.gray}
                size={ButtonSizes.fullWidth}
                onClick={() => { setGuidelinesOpen(false) }}
              >
                Close
              </Button>
            </div>
          </XGSPopup>
        </div>
      )}

      {values.packageType === "PALLET" && (
        <XGSFormSelect
          name="commodityCode"
          label="Commodity"
          labelMode={LabelModes.column}
          options={commonState.commodityCodes}
          className="xgs-pickup__field"
          isClearable
          isSearchable
          async
          controlled
          placeholder="Select..."
        />
      )}

      <div className="xgs-item-form__row">
        {values.packageType === "PALLET" && (
          <div className="xgs-pickup__row__item">
            <XGSFormInput
              type="text"
              name="units"
              label="Pieces on Pallet:"
              required={true}
              requiredAsteriskDisabled={false}
              labelMode={LabelModes.column}
              className="xgs-item-form__field"
              placeholder="Count..."
            />
          </div>
        )}

        {values.packageType === "ROLL" && (
          <div className="xgs-pickup__row__item">
            <XGSFormInput
              type="text"
              name="yards"
              label="Sq. Yards:"
              required={true}
              requiredAsteriskDisabled={false}
              labelMode={LabelModes.column}
              className="xgs-item-form__field"
              placeholder="Count..."
            />
          </div>
        )}

        <div className="xgs-pickup__row__item">
          <XGSFormInput
            type="text"
            name="weight"
            label={values.packageType === "ROLL" ? "Roll Weight" : "Pallet Weight"}
            required={true}
            requiredAsteriskDisabled={false}
            labelMode={LabelModes.column}
            className="xgs-item-form__field"
            placeholder="Pounds..."
          />
        </div>
      </div>
      <div className="xgs-item-form__row">
        {values.packageType === "PALLET" && (<div className="xgs-pickup__row__item">
          <XGSFormInput
            type="text"
            name="length"
            label="Pallet Length:"
            required={false}
            requiredAsteriskDisabled={true}
            labelMode={LabelModes.column}
            className="xgs-item-form__field"
            value={(values.length && !Number.isNaN(values.length)) ? values.length : ""}
            placeholder="in..."
          />
        </div>)}

        <div className="xgs-pickup__row__item">
          <XGSFormInput
            type="text"
            name="width"
            label={values.packageType === "ROLL" ? "Roll Width:" : "Pallet Width:"}
            required={false}
            requiredAsteriskDisabled={true}
            labelMode={LabelModes.column}
            className="xgs-item-form__field"
            value={(values.width && !Number.isNaN(values.width)) ? values.width : ""}
            placeholder="in..."
          />
        </div>

        {values.packageType === "ROLL" && (<div className="xgs-pickup__row__item">
          <XGSFormInput
            type="text"
            name="diameter"
            label="Roll Diameter:"
            required={false}
            requiredAsteriskDisabled={true}
            labelMode={LabelModes.column}
            className="xgs-item-form__field"
            value={(values.diameter && !Number.isNaN(values.diameter)) ? values.diameter : ""}
            placeholder="in..."
          />
        </div>)}

        {values.packageType === "PALLET" && (
          <div className="xgs-pickup__row__item">
            <XGSFormInput
              type="text"
              name="height"
              label="Pallet Height:"
              required={false}
              requiredAsteriskDisabled={true}
              labelMode={LabelModes.column}
              className="xgs-item-form__field"
              value={(values.height && !Number.isNaN(values.height)) ? values.height : ""}
              placeholder="in..."
            />
          </div>
        )}
      </div>
      <XGSFormSelect
        name="ratingClass"
        label="Class:"
        labelMode={LabelModes.column}
        options={values.packageType === "PALLET" ? commonState.freightClasses.filter((freightClass: XGSSelectOption) => freightClass.value !== "1" && freightClass.value !== "3") : commonState.freightClasses}
        className="xgs-pickup__field"
        required={props.forCustomer}
        isClearable
        isSearchable
        async
        controlled
        placeholder="Select..."        
      />
      {!(commonState.freightClasses.find((freightClass: XGSSelectOption) => freightClass.value === "1" || freightClass.value === "3")) && (commonState.freightClasses.length > 0) && (
        <div className="xgs-bol__item-form__freight-notes">
          <strong>Note:</strong> Carpet and Carpet Tile class can only be used for outbound shipments from North Georgia or South California.
        </div>
      )}
      <div className="xgs-item-form__row">
        <XGSFormInput
          type="text"
          name="sidemark"
          label="Sidemark:"
          required={false}
          requiredAsteriskDisabled={true}
          labelMode={LabelModes.column}
          className="xgs-item-form__field xgs-item-form__field--full-width"
        />
      </div>

      {props.forCustomer && (
        <div className="xgs-item-form__row">
          <XGSFormInput
            type="text"
            name="description"
            label="Description:"
            required={false}
            requiredAsteriskDisabled={false}
            labelMode={LabelModes.column}
            className="xgs-bol__item-form__field"
            disabled={!!props.quoteNumber}
          />
        </div>)}

      <div className="xgs-pickup__textarea xgs-item-form__row" style={{ marginBottom: 16 }}>
        <XGSFormTextarea
          name="notes"
          label="Special Notes:"
          required={false}
          rows={4}
          counter={150}
        />
      </div>
    </div>
  );
};

export default FreightItemFormFields;