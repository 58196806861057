import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import { ItemModel } from "../../../../app/data/common/models";
import { ReturnItemSchema } from "../../../../app/data/return/models";
import ReturnState from "../../../../slices/return/ReturnState";
import { returnSelector } from "../../../../slices/return/returnSlice";
import FreightItemFormFields from "../../../common/freight-item/freightItemFormFields";
import { freightItemValues } from "../../../../app/data/common/freightItem";

interface ReturnItemFormProps {
  itemIndex?: number,
  editMode?: boolean;
  onCancel?: () => void,
  onSubmit: (data: ItemModel) => void
};

let initialValues: ItemModel = freightItemValues;

const ReturnItemForm: React.FC<ReturnItemFormProps> = (props) => {
  const {
    itemIndex,
    editMode,
    onCancel,
    onSubmit
  } = props;
  const returnState: ReturnState = useSelector(returnSelector);
  const returnItemRef = useRef<any>(null);

  useEffect(() => {
    if (itemIndex === undefined) {
      // Formik has issue when passing undefined values (https://github.com/formium/formik/issues/2332), so we passing empty strings  
      initialValues = freightItemValues;
      returnItemRef.current.resetForm({ values: initialValues });
    } else {
    initialValues = {
      packageType: returnState.items[itemIndex].packageType,
      units: returnState.items[itemIndex].units || "",
      ratingClass: returnState.items[itemIndex].ratingClass || "",
      description: returnState.items[itemIndex].description || "",
      notes: returnState.items[itemIndex].notes || "",
      yards: returnState.items[itemIndex].yards || "",
      weight: returnState.items[itemIndex].weight || "",
      length: returnState.items[itemIndex].length || "",
      width: returnState.items[itemIndex].width || "",
      diameter: returnState.items[itemIndex].diameter || "",
      height: returnState.items[itemIndex].height || "",
      sidemark: returnState.items[itemIndex].sidemark || "",
      commodityCode: returnState.items[itemIndex].commodityCode,
    };
    returnItemRef.current.resetForm({ values: initialValues });
  }
    // eslint-disable-next-line
  }, [itemIndex]);  

  return (
    <div className="xgs-bol__item-form">
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={ReturnItemSchema}
        innerRef={returnItemRef}
        enableReinitialize
      >
        {(props: FormikProps<ItemModel>) => (
          <Form>
            <FreightItemFormFields
              forCustomer
            />

            <div className="xgs-bol__item-form__misc-text">
              <ul>
                <li>If <strong>description</strong> isn't provided it will be listed as &quot;Miscellaneous&quot;.</li>
                <li>Any <strong>notes</strong> added will appear under the line item on the bill of lading.</li>
                <li><strong>Sidemark</strong> will appear on bill of lading and shipping label.</li>
              </ul>
            </div>
            <div className="xgs-bol__item-form__row">
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={
                  !props.isValid ||
                  !props.values.units ||
                  !!((editMode || itemIndex === undefined) && !props.dirty)
                }
                className="xgs-bol__item-form__button"
              >
                {editMode ? "Save changes" : "Add item"}
              </Button>
              {editMode && (
                <Button
                  type="button"
                  theme={ButtonThemes.blue}
                  onClick={onCancel}
                  className="xgs-bol__item-form__button"
                >
                  Cancel
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ReturnItemForm;