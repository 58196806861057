import React, { DetailedHTMLProps, memo } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Loading from "../loading/loading";
import XGSIcon from "../icon/xgsIcon";
import { cn } from "../../services/common/className";
import "./button.scss";

export enum ButtonThemes {
  gray = "gray",
  white = "white",
  whiteBlue = "white-blue",
  green = "green",
  red = "red",
  redInverted = "red-inverted",
  blue = "blue",
  purple = "purple",
  transparent = "transparent",
  blueInverted = "blue-inverted"
}

export enum ButtonSizes {
  small = "small",
  medium = "medium",
  auto = "auto",
  fullWidth = "full-width",
  autoMedium = "auto-medium",
}
export interface ButtonProps
  extends DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  theme: ButtonThemes;
  size?: ButtonSizes;
  spinner?: boolean;
  icon?: IconProp;
  mods?: {};
}

export const SmallButton: React.FC<ButtonProps> = ({className, ...props}) => {
  return (
    <Button className={`${className} xgs-btn-small`} {...props}>
      {props.children}
    </Button>
  );
}

const Button: React.FC<ButtonProps> = memo(({
  theme,
  size,
  spinner,
  className,
  icon,
  ...props
}) => {
  return (
    <button
      className={cn("xgs-btn")({ theme, size, spinner, ...props.mods }, className)}
      {...props}
      disabled={spinner ? true : (props.disabled || false)}
    >
      {spinner && (
        <div className="xgs-btn__spinner">
          <Loading isLoading={true} />
        </div>
      )}
      {props.children}
      {icon && (
        <div className="xgs-btn__icon-container"><XGSIcon className="xgs-btn__icon" icon={icon} /></div>
      )}
    </button>
  );
});

export default Button;
