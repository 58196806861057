import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import TrackingFilter from "./tracking-filter/trackingFilter";
import Table from "../../../ui-components/table/table";
import FiltersToggle from "../../../ui-components/filters-toggle/filtersToggle";
import getTrackShipmentColumns from "./trackingColumns";
import TrackShipmentState from "../../../slices/track-shipment/TrackShipmentState";
import {
  trackShipmentSelector,
  searchShipments
} from "../../../slices/track-shipment/trackShipmentSlice";
import { userSelector } from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";
import { UserUtils } from "../../../app/data/user/userUtils";
import Banner, { BannerColor } from "../../../ui-components/banner/banner";
import XGSNavLink from "../../../ui-components/xgs-nav-link/xgsNavLink";
import {ReactComponent as AiIcon} from "../../../images/ai.svg";

import "./tracking.scss";

const Tracking: React.FC<{}> = memo((props) => {
  const userState: UserState = useSelector(userSelector);
  const trackShipmentState: TrackShipmentState = useSelector(trackShipmentSelector);
  const dispatch = useDispatch();

  const onInfiniteScroll = () => {
    dispatch(searchShipments(trackShipmentState.request, true));
  };

  return (
    <ContentContainer
      titleComponent={
        <ContentContainerToolbar title="Track Shipments">
          <FiltersToggle />
        </ContentContainerToolbar>
      }
      header={
        <TrackingFilter source="TRACKING"/>
      }
      className="xgs-tracking-header"
    >
      <div className="xgs-tracking">
        {UserUtils.isEmployee(userState.profile) && (
          <Banner mods={{ color: BannerColor.purple }} mix="xgs-tracking__ai-banner">
            <div className="xgs-tracking__ai-banner__content">
              Get instant shipment details with our new AI chat feature.
            </div>

            <XGSNavLink
              className="xgs-banner__action-button"
              to={"/ai/shipment-chat"}
            >
              <AiIcon />Chat with AI
            </XGSNavLink>
          </Banner>
        )}

        {!trackShipmentState.loadingFailed && (
          <>
            <Table
              isLoading={trackShipmentState.loading}
              columns={
                getTrackShipmentColumns(                  
                  UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile) || false, 
                  UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT")
                )
              }
              data={trackShipmentState.shipments}
              infiniteScroll={true}
              infiniteScrollLoading={trackShipmentState.loadingPortion}
              infiniteScrollHasNext={!trackShipmentState.loadedAll}
              onInfiniteScroll={onInfiniteScroll}
              rowHeight={82}
              minTableHeight={320}
              maxTableWidth={1110}
              responsive={true}
            />
          </>
        )}
        {trackShipmentState.loadingFailed && (
          <XGSErrorMessage>{trackShipmentState.error || "Error while obtaining the list of shipments"}</XGSErrorMessage>
        )}
      </div>
    </ContentContainer>
  );
});

export default Tracking;
