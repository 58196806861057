import React from "react";
import Loading from "../../loading/loading";
import { cn } from "../../../services/common/className";
import "./contentContainerToolbar.scss";

export interface ContentContainerToolbarProps {
  title?: string;
  isLoading?: boolean;
  className?: string;
  mods?: {
    ["full-width"]?: boolean;
  };
}

const ContentContainerToolbar: React.FC<ContentContainerToolbarProps> = (
  props
) => {
  return (
    <div className={cn("xgs-site__content-container__toolbar")(props.mods, props.className)}>
      <div className="xgs-site__content-container__title">{props.title} <Loading isLoading={props.isLoading} /></div>
      <div className="xgs-site__content-container__toolbar__buttons">
        {props.children}
      </div>
    </div>
  );
};

export default ContentContainerToolbar;
