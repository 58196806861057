import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSCheckbox from "../../../ui-components/xgs-checkbox/xgsCheckbox";
import BolState from "../../../slices/bol/BolState";
import {
  bolSelector,
  downloadDocument,
  updateBolPickup,
  updateBolPickupStatus
} from "../../../slices/bol/bolSlice";
import ShareReturn from "../return/share";
import paperIcon from "../../../images/paper_blue.svg";
import shareIcon from "../../../images/share_blue.svg";
import PickupDetails from "./pickupDetails";
import { CustomerPickup } from "../../../app/data/customer-pickup/models";

import "../bol.scss";

export interface BolDetailsProps {
  probill: string | undefined;
  show: boolean;
  onClose: () => void;
};

const BolDetails: React.FC<BolDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const bolState: BolState = useSelector(bolSelector);
  const [bolData, setBolData] = useState<any>(undefined);
  const [bolDocuments, setBolDocuments] = useState(true);
  const [labelsDocuments, setLabelsDocuments] = useState(true);

  const onClickDownload = () => {
    if (!props.probill) return;
    let type;
    if (bolDocuments && labelsDocuments) type = "ALL";
    if (bolDocuments && !labelsDocuments) type = "BOL";
    if (!bolDocuments && labelsDocuments) type = "LABEL";
    dispatch(downloadDocument(props.probill, type, (file: Blob) => {
      let documentfileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = documentfileURL;
      link.download = props.probill + "_" + type.toLowerCase();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }));
  };

  const isReturn = () => {
    return bolData?.bolType === "RETURN";
  };

  const getBolPickupDetails = () => {
    return {
      ...bolData,
      shipper: {
        address: bolData?.shipperInfo.address,
        accountNumber: bolData?.shipper,
      },
    } as CustomerPickup;
  }

  useEffect(() => {
    if (!bolState.requestFailed) return;
    toast.error(bolState.requestError || "Error", { autoClose: 4000 });
  }, [bolState.requestFailed, bolState.requestError]);

  useEffect(() => {
    if (!props.probill) return;
    const bolObj = bolState.bols.find(bol => bol.probill === props.probill);
    setBolData(bolObj);
  }, [props.probill, bolState.bols]);  

  return (
    <SlideOutSidebar
      header={bolData?.pickupDate ? "Pickup Details" : "Bill of Lading Details"}
      onClose={props.onClose}
      show={props.show}
      size="small-605"
    >
      <div className="xgs-bol__details">
        <div className="xgs-bol__details__block">
          <div className="flex-space-between">
            <div className="xgs-bol__details__block__header">
              <div className="xgs-bol__details__block__header__icon">
                <img src={paperIcon} alt="paper" />
              </div>
              <div className="xgs-bol__details__block__header__text">
                <span>Bill of Lading</span>
              </div>
            </div>
            <div className="xgs-bol__details__probill">
              <span>Probill:</span> <a href={"/shipments/" + props.probill} target="_blank" rel="noopener noreferrer" className="probill-number">{props.probill}</a>
            </div>
          </div>
          <div className="xgs-bol__details__controls">
            <XGSCheckbox
              name="bol"
              onChange={() => setBolDocuments(!bolDocuments)}
              checked={bolDocuments}
            >
              BOL
            </XGSCheckbox>
            <XGSCheckbox
              name="labels"
              onChange={() => setLabelsDocuments(!labelsDocuments)}
              checked={labelsDocuments}
            >
              Shipping Labels
            </XGSCheckbox>
            <Button
              theme={ButtonThemes.blue}
              className="xgs-bol__details__controls__button"
              onClick={() => onClickDownload()}
              spinner={bolState.requestStarted && (bolState.requestCreator === "download-BOL" || bolState.requestCreator === "download-LABEL" || bolState.requestCreator === "download-ALL")}
              disabled={
                (bolState.requestStarted && (bolState.requestCreator === "download-BOL" || bolState.requestCreator === "download-LABEL" || bolState.requestCreator === "download-ALL"))
                || (!bolDocuments && !labelsDocuments)
              }
            >
              Download Docs
            </Button>
          </div>
        </div>
        {bolData?.pickupDate && (
          <PickupDetails
            pickup={getBolPickupDetails()}
            isReturn={isReturn()}
            onCancelPickup={() => {
              dispatch(updateBolPickupStatus({
                probill: props.probill,
                status: "CANCELED"
              }));
            }}
            onReschedulePickup={(
              newPickupId: string,
              newPickupNumber: string,
              date: string,
              readyTime: string,
              closeTime: string) => {
              dispatch(updateBolPickup({
                probill: props.probill,
                pickupId: newPickupId,
                pickupNumber: newPickupNumber,
                date,
                readyTime,
                closeTime
              }));
            }}
        />
        )}
        {isReturn() && props.probill && (
          <div className="xgs-bol__details__block">
            <div className="xgs-bol__details__block__header">
              <div className="xgs-bol__details__block__header__icon">
                <img src={shareIcon} alt="share" />
              </div>
              <div className="xgs-bol__details__block__header__text">
                Share this return
              </div>
            </div>
            <ShareReturn probill={Number(props.probill)} />
          </div>
        )}
      </div>
    </SlideOutSidebar>
  );
};

export default BolDetails;
