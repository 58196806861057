import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { CreateLateReasonTasks, CreateLateReasonsState } from "./serviceDisruptionState";
import ServiceDisruptionService from "../../../app/data/service-disruption/serviceDisruptionService";
import { CreateLateReasonBaseRequest, ServiceDisruptionProbill } from "../../../app/data/service-disruption/models";
import { getServiceDisruptionProbills } from "./serviceDisruptionProbillsSlice";

const initialState: CreateLateReasonsState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  totalTasks: 0,
  completedTasks: 0,
  successfullTasks: 0,
  failedTasks: 0,
  failedProbills: []
};

const serviceDisruptionService = ServiceDisruptionService.getInstance();

export const createLateReasonsSlice = createSlice({
  name: "createLateReasons",
  initialState,
  reducers: {
    resetCreateLateReasons: (state) => initialState,
    createLateReasonsLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    createLateReasonsLoadingSucceed: (state) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
    },
    createLateReasonsLoadingFailed: (state) => {
      state.loading = false;
      state.loadingFailed = true;
    },
    updateTasks: (state, { payload }) => {
      state.totalTasks = payload.totalTasks;
      state.completedTasks = payload.completedTasks;
      state.successfullTasks = payload.successfullTasks;
      state.failedTasks = payload.failedTasks;
      if (payload.failedProbills) state.failedProbills = [...payload.failedProbills]
    },
  },
});

export const {
  resetCreateLateReasons,
  createLateReasonsLoadingStarted,
  createLateReasonsLoadingSucceed,
  createLateReasonsLoadingFailed,
  updateTasks,
} = createLateReasonsSlice.actions;

export const createLateReasonsSelector = (state: IState) => state.createLateReasons;

export const createLateReasonsForProbills = (
  terminalNumber: number,
  createLateReasonBaseRequest: CreateLateReasonBaseRequest,
  selectedprobills: ServiceDisruptionProbill[],
  onSuccess?: () => void,
  onError?: (error: string) => void
): AppThunk => async (dispatch) => {
  let tasks: CreateLateReasonTasks = {
    totalTasks: 0,
    completedTasks: 0,
    successfullTasks: 0,
    failedTasks: 0,
    failedProbills: []
  };
  tasks.totalTasks = selectedprobills.length;
  dispatch(createLateReasonsLoadingStarted());
  dispatch(updateTasks(tasks));
  for (let probillIdx = 0; probillIdx < selectedprobills.length; probillIdx++) {
    let request = { ...createLateReasonBaseRequest, probillNumber: selectedprobills[probillIdx].probillNumber };
    const response = await serviceDisruptionService.createLateReason(request);
    if (response.ok() && response.data && response.data.result) {
      tasks.successfullTasks++;
    } else {
      tasks.failedTasks++
      tasks.failedProbills = [...tasks.failedProbills, selectedprobills[probillIdx]]
    }
    tasks.completedTasks++;
    dispatch(updateTasks(tasks));
  }
  dispatch(getServiceDisruptionProbills(terminalNumber, onError));
  if(tasks.failedTasks === 0){
    dispatch(createLateReasonsLoadingSucceed());
    onSuccess && onSuccess();
  }else{
    dispatch(createLateReasonsLoadingFailed())
    onError && onError(`Failed to create late reasons for ${tasks.failedTasks} probill${(tasks.failedTasks > 1) ? 's' : ''}`);
  }
};

const createLateReasonsReducer = createLateReasonsSlice.reducer;
export default createLateReasonsReducer;