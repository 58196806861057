import React, { useEffect, useRef, useState } from "react";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSFormCheckbox from "../../../ui-components/form/checkbox/xgsFormCheckbox";
import { Form, Formik, FormikProps } from "formik";
import {
  companyAccountDetailsSelector,
  getAccountDetails,
  resetCompanyAccountDetails,
  updateAccountDetails,
  updateAccountDetailsLocally,
} from "../../../slices/company-settings/companyAccountDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import "./accountGeneralSettings.scss";
import { toast } from "react-toastify";
import { SubAccount, SubAccountRequestSchema } from "../../../app/data/company-settings/models";
import Loading from "../../../ui-components/loading/loading";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSRegularIcons from "../../../ui-components/icon/xgsRegularIcons";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";
import { ACCOUNT_GENERAL_SETTINGS, ACCOUNT_SERVICES } from "./constants";
import { AccountGeneralSettingsLogs } from "./accountGeneralSettingsLogs";

interface AccountGeneralSettingsProps {
  accountNumber: number;
  accountId: string;
}

export const AccountGeneralSettings: React.FC<AccountGeneralSettingsProps> = (props) => {
  const dispatch = useDispatch();
  const companyAccountDetailsState = useSelector(companyAccountDetailsSelector);
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [showServicesHelp, setShowServicesHelp] = useState(false);
  const [showLogs, setShowLogs] = useState(false);

  const onSubmit = async (values: SubAccount) => {
    dispatch(
      updateAccountDetails(
        props.accountId,
        { ...values, phone: phoneValue?.replace(/[^0-9]/g, "")},
        () => {
          toast.info("Updated details");
          dispatch(updateAccountDetailsLocally(values));
        },
        () => toast.error("Something went wrong")
      )
    );
  };

  const initialFormValues: SubAccount = {
    accountNumber: props.accountNumber,
    accountName: companyAccountDetailsState.accountDetails?.accountName || "",
    address: companyAccountDetailsState.accountDetails?.address || "",
    city: companyAccountDetailsState.accountDetails?.city || "",
    country: companyAccountDetailsState.accountDetails?.country || "",
    phone: companyAccountDetailsState.accountDetails?.phone?.formatPhone() || "",
    state: companyAccountDetailsState.accountDetails?.state || "",
    zip: companyAccountDetailsState.accountDetails?.zip || "",
    contactPersonName: companyAccountDetailsState.accountDetails?.contactPersonName || "",
    generalEmail: companyAccountDetailsState.accountDetails?.generalEmail || "",
    services: {
      liftGateRequired:
        companyAccountDetailsState.accountDetails?.services.liftGateRequired || false,
      limitedAccess: companyAccountDetailsState.accountDetails?.services.limitedAccess || false,
      residential: companyAccountDetailsState.accountDetails?.services.residential || false,
      detentionFacility:
        companyAccountDetailsState.accountDetails?.services.detentionFacility || false,
      insideDelivery: companyAccountDetailsState.accountDetails?.services.insideDelivery || false,
      appointmentRequired:
        companyAccountDetailsState.accountDetails?.services.appointmentRequired || false,
      sortOrSegregate: companyAccountDetailsState.accountDetails?.services.sortOrSegregate || false,
    },
  };

  useEffect(() => {
    dispatch(getAccountDetails(props.accountId));
    return () => {
      dispatch(resetCompanyAccountDetails());
    };
  }, [dispatch, props.accountId]);

  const formRef = useRef<FormikProps<SubAccount>>(null);

  useEffect(() => {
    let phoneNumber = companyAccountDetailsState.accountDetails?.phone;
    setPhoneValue(phoneNumber || "");
  }, [companyAccountDetailsState.accountDetails]);

  return (
    <div className="xgs-account-general-settings">
      <Button className="xgs-account-general-settings__logs__button" theme={ButtonThemes.blue} onClick={() => setShowLogs(true)}>Logs</Button>
      <Loading isLoading={companyAccountDetailsState.fetch_was_started} />
      {companyAccountDetailsState.accountDetails && companyAccountDetailsState.fetch_was_succeed && (
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          enableReinitialize
          validationSchema={SubAccountRequestSchema}
          innerRef={formRef}
        >
          {(props: FormikProps<SubAccount>) => (
            <Form className="xgs-account-general-settings__form">
              <div className="xgs-account-general-settings__form__section">Special Services</div>
              <div className="xgs-account-general-settings__form__info">Note: Accessorial fees based on negotiated rates.</div>
              <div className="xgs-account-general-settings__form__services">
                <div
                  className="xgs-account-general-settings__form__services__help"
                  title="Click to view related help instructions"
                  onClick={() => setShowServicesHelp(true)}
                >
                  <XGSIcon icon={XGSRegularIcons.faQuestionCircle} size="1x" />
                </div>
                {ACCOUNT_GENERAL_SETTINGS.map(({ name, label }) => (
                  <XGSFormCheckbox name={name}>
                    {label}
                  </XGSFormCheckbox>
                ))}                
              </div>
              <div className="xgs-account-general-settings__form__buttons">
                <Button
                  theme={ButtonThemes.blue}
                  style={{ marginTop: 8 }}
                  disabled={!props.dirty}
                  spinner={companyAccountDetailsState.update_was_started}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <ConfirmationModal
        opened={showServicesHelp}
        header="Services Help"
        confirmButtonText="Close"
        onConfirm={() => setShowServicesHelp(false)}
      >
        <div style={{ marginBottom: 16 }}>
          The meaning of the special services is described below to help you understand them.
        </div>
        {ACCOUNT_SERVICES.map((field) => (
          <div style={{ marginBottom: 8 }}>
            <strong>{field.label}</strong> -{" "}
            {field.description.charAt(0).toLowerCase() + field.description.slice(1)}
          </div>
        ))}
      </ConfirmationModal>
      {props.accountId && <AccountGeneralSettingsLogs onClose={() => setShowLogs(false)} show={showLogs} accountId={props.accountId}/>}
    </div>
  );
};
